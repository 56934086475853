#comments {
    &.offer-container__comment {
        margin-top: 185px;
        @media(max-width: $laptop) {
            margin-top: 145px;
        }
        @media(max-width: $tablet) {
            margin-top: 105px;
        }
        @media(max-width: $mobile) {
            margin-top: 85px;
        }
        p {
            text-align: center;
        }
        img {
            left: -50px;
            @media(max-width: $tablet) {
                left: 0;
                top: -60px;
            }
        }
    }
}

.swiper-container-comment {
    width: 930px;
    @media (max-width: $tablet) {
        width: 100%;
    }
    .swiper-slide__content {
        width: 930px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: $tablet) {
            width: 100%;
        }
    }
}