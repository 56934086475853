.logo-footer {
    @media(max-width: $mobile){
        display: flex;
        justify-content: center;
    }
    img{
        width: 200px;
        height: 45px;
        margin: 85px 0;

        @media(max-width: $laptop){
            margin: 65px 0;
        }
        @media(max-width: $tablet){
            margin: 40px 0;
        }
        @media(max-width: $mobile){
            margin: 15px 0;
        }
    }
}