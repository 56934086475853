#awards {
    margin: 195px 0;
    @media(max-width: $laptop){
        margin: 145px 0;
    }
    @media(max-width: $tablet){
        margin: 95px 0;
    }
    @media(max-width: $mobile){
        margin: 45px 0;
    }
    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 160px;
        @media(max-width: $laptop){
            flex-wrap: wrap;
            padding: 0 80px;
        }
        @media(max-width: $tablet){
            padding: 0 40px;
        }
        @media(max-width: $mobile){
            padding: 0;
        }

        li {
            // margin: 10px 40px 10px 0;
            // &:last-child {
            //     margin-right: 0;
            // }

            img {
                max-height: 50px;
            }
        }
    }
}