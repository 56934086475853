html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1.3;
  font-size: 10px;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  color: #22101d;
  font-style: normal;
  position: relative;
  right: 0;
  transition: .3s ease-in-out;
  overflow-x: hidden;
  color: #22101d;
}

#test {
  height: 1000px;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

b {
  font-weight: 700;
}

.st0 {
  transition: .3s ease-in-out;
}

svg {
  width: 100%;
  height: 100%;
}

sup {
  vertical-align: super;
}

.to-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.margin-to-center {
  margin: 0 auto;
}

.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.wrapper {
  padding: 0 160px;
}

@media (max-width: 1600px) {
  .wrapper {
    padding: 0 80px;
  }
}

@media (max-width: 1200px) {
  .wrapper {
    padding: 0 40px;
  }
}

@media (max-width: 769px) {
  .wrapper {
    padding: 0 15px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 0 33px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #ab9569;
  border-radius: 30px;
  color: #ab9569;
  transition: .3s ease-in-out;
}

.button:hover {
  color: #fff;
  background-color: #ab9569;
  box-shadow: 0px 8px 35px -16px rgba(0, 0, 0, 0.75);
}

.button.long {
  width: 290px;
}

.button.reverse {
  color: #fff;
  background-color: #ab9569;
}

.button.reverse:hover {
  color: #ab9569;
  background-color: #fff;
}

.buy-ticket {
  margin-top: 214px;
}

@media (max-width: 1900px) {
  .buy-ticket {
    margin-top: 20px;
  }
}

.line {
  height: 1px;
  background-color: #ab9569;
}

.swiper-main-container {
  position: relative;
}

.swiper-main-container h2 {
  font-size: 6em;
  font-family: ratiomodern, serif;
  font-weight: 400;
}

@media (max-width: 1600px) {
  .swiper-main-container h2 {
    font-size: 5em;
  }
}

@media (max-width: 1200px) {
  .swiper-main-container h2 {
    font-size: 4em;
    margin-bottom: 40px;
  }
}

@media (max-width: 769px) {
  .swiper-main-container h2 {
    font-size: 3em;
    margin-bottom: 20px;
  }
}

.line-swiper {
  position: absolute;
  right: 0;
  z-index: 9;
  transform: translate(160px, -50%);
}

@media (max-width: 769px) {
  .line-swiper {
    display: none;
  }
}

.swiper-pagination-bullets {
  display: flex;
  flex-direction: column;
  align-items: center;
  left: -40px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}

@media (max-width: 1200px) {
  .swiper-pagination-bullets {
    left: -20px;
  }
}

@media (max-width: 769px) {
  .swiper-pagination-bullets {
    transform: translate(-50%, 50%);
    top: 0;
    left: 50%;
    bottom: -10px;
    flex-direction: row;
  }
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  opacity: 1;
  background: #fff;
  border: 1px solid #ab9569;
  margin: 11px 0;
  outline: none;
}

@media (max-width: 769px) {
  .swiper-pagination-bullet {
    margin: 11px 11px 0 11px;
  }
}

.swiper-pagination-bullet-active {
  width: 6px;
  height: 6px;
  background: #ab9569;
}

.swiper-container-photo {
  max-width: 610px;
  max-height: 435px;
  overflow: hidden;
  z-index: -1;
}

.form-meet {
  margin: 105px 320px 135px 320px;
}

@media (max-width: 1600px) {
  .form-meet {
    margin: 85px 240px 95px 240px;
  }
}

@media (max-width: 1200px) {
  .form-meet {
    margin: 55px 160px 75px 160px;
  }
}

@media (max-width: 769px) {
  .form-meet {
    margin: 15px 0;
  }
}

.form-meet h3 {
  font-size: 60px;
  font-family: ratiomodern, serif;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
}

@media (max-width: 769px) {
  .form-meet h3 {
    font-size: 48px;
  }
}

.form-meet p {
  font-size: 20px;
}

@media (max-width: 769px) {
  .form-meet p {
    font-size: 16px;
  }
}

.form-meet .row {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 1fr;
  grid-template-rows: 1fr;
  gap: 1px 30px;
  grid-template-areas: ". . .";
  margin: 60px 0 0 0;
}

@media (max-width: 769px) {
  .form-meet .row {
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: column;
  }
  .form-meet .row label {
    margin-top: 25px;
  }
}

.form-meet .row.row-4 {
  grid-template-columns: 1.8fr 0.5fr 0.5fr 0.5fr;
  grid-template-areas: ". . . .";
}

.form-meet .row.row-2 {
  grid-template-columns: 1fr 1fr;
  margin-top: 25px;
  grid-template-areas: ". .";
}

.form-meet .flexLabel {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.form-meet .flexLabel input {
  width: 100%;
}

.form-meet label {
  font-weight: bold;
  font-size: 12px;
}

.form-meet input {
  width: 100%;
  padding-left: 15px;
  font-size: 14px;
  height: 30px;
  border: unset;
  border-bottom: 1px solid #767074;
}

.form-meet br {
  display: none;
}

.form-meet input[type=submit] {
  width: 290px;
  height: 47px;
  margin-top: 35px;
  border: unset;
  border: 1px solid #ab9569;
  border-radius: 50px;
  transition: .3s ease-in-out;
  background: #fff;
  padding: 0;
}

.form-meet input[type=submit]:hover {
  color: #fff;
  cursor: pointer;
  background: #ab9569;
}

#accept-rules {
  margin-top: 20px;
  cursor: pointer;
}

#accept-rules label {
  display: flex;
  font-weight: normal;
}

.screen-reader-response, .wpcf7-validation-errors, .wpcf7-not-valid-tip {
  margin-top: 10px;
  font-size: 12px;
  color: red;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox + span {
  position: relative;
  cursor: pointer;
  padding: 0;
  padding-left: 25px;
}

.styled-checkbox + span:before {
  content: '';
  position: absolute;
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 15px;
  height: 15px;
  background: white;
  border: 1px solid #22101d;
  border-radius: 3px;
  transition: .2s ease;
  left: 0;
}

.styled-checkbox:hover + span:before {
  background: #ab9569;
}

.styled-checkbox:focus + span:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  border: 1px solid #ab9569;
}

.styled-checkbox:checked + span:before {
  background: #ab9569;
  border: 1px solid #ab9569;
}

.styled-checkbox:disabled + span {
  color: #b8b8b8;
  cursor: auto;
}

.styled-checkbox:disabled + span:before {
  box-shadow: none;
  background: #ddd;
}

.styled-checkbox:checked + span:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 7px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

#freshMailPopUp__background {
  position: relative;
}

#freshMailPopUp__background #fm_form_1 {
  background-color: #ab9569 !important;
  padding: 0 !important;
}

#freshMailPopUp__background input[type=text] {
  border: unset !important;
  border-bottom: 1px solid #fff !important;
  font-size: 14px !important;
  background: #ab9569 !important;
  width: 290px !important;
  color: #fff !important;
}

#freshMailPopUp__background input[type=text]::placeholder {
  color: #fff !important;
  opacity: 0.5 !important;
}

#freshMailPopUp__background input[type=checkbox] {
  margin-top: 20px !important;
}

#freshMailPopUp__background button {
  border: 1px solid #fff !important;
  border-radius: 30px !important;
  width: 130px !important;
  height: 37px !important;
  color: #fff !important;
  background-color: #ab9569 !important;
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
}

#freshMailPopUp__background button:hover {
  color: #ab9569 !important;
  background-color: #fff !important;
}

#freshMailPopUp__background p span {
  margin-top: 20px;
  color: #fff;
}

.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 43px;
  background-color: #651932;
}

@media (max-width: 769px) {
  .header-top {
    flex-direction: column;
    padding: 15px 35px;
    margin-bottom: 20px;
  }
}

.header-top__right-wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 769px) {
  .header-top__right-wrapper {
    flex-direction: column;
    margin-top: 5px;
  }
}

.info-top {
  color: #fff;
  font-size: 1.2em;
}

@media (max-width: 769px) {
  .info-top {
    text-align: center;
  }
}

.reservation-info-top {
  font-size: 1.4em;
  color: #ab9569;
}

.social-media-top {
  display: flex;
  justify-content: space-between;
  margin-left: 100px;
  width: 130px;
}

@media (max-width: 1600px) {
  .social-media-top {
    margin: 0x 0 0 40px;
  }
}

@media (max-width: 769px) {
  .social-media-top {
    margin: 5px 0 0 40px;
  }
}

.social-media-top__item {
  display: block;
  max-width: 23px;
  height: 14px;
}

.social-media-top__item:hover .st0, .social-media-top__item:hover .st1 {
  fill: #ab9569;
}

.main-nav__wrapper li {
  margin-left: 25px;
  position: relative;
}

@media (max-width: 769px) {
  .main-nav__wrapper li {
    margin: 18px 0 0 0;
    display: flex;
    justify-content: center;
  }
}

.main-nav__wrapper li a {
  transition: .3s ease-in-out;
}

.main-nav__wrapper li a:after {
  content: '';
  width: 0%;
  height: 1px;
  position: absolute;
  bottom: -20px;
  left: 0;
  background-color: #ab9569;
  transition: .3s ease-in-out;
  left: 50%;
}

@media (max-width: 769px) {
  .main-nav__wrapper li a:after {
    bottom: -8px;
  }
}

@media (min-width: 769px) {
  .main-nav__wrapper li a:hover {
    color: #ab9569;
  }
  .main-nav__wrapper li a:hover:after {
    animation: line .3s ease-in-out forwards;
  }
}

.main-nav__wrapper li a.is-active {
  color: #ab9569;
}

.main-nav__wrapper li a.is-active:after {
  width: 100%;
  left: 0;
  background-color: #ab9569;
}

@media (max-width: 769px) {
  .main-nav__wrapper li a.nested {
    text-align: center;
    position: relative;
  }
}

.main-nav__wrapper li a.nested:before {
  content: '';
  position: absolute;
  bottom: -24px;
  width: 0;
  left: calc(50% - 4px);
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #ab9569;
  transform: rotate(-180deg);
  opacity: 0;
}

@media (max-width: 769px) {
  .main-nav__wrapper li a.nested:before {
    bottom: -12px;
  }
}

@media (min-width: 769px) {
  .main-nav__wrapper li a.nested:hover:before {
    animation: triangle .3s ease-in-out forwards;
  }
}

.main-nav__wrapper li a.nested.is-active:before {
  opacity: 1;
  bottom: -19px;
  transform: rotate(0);
}

@media (max-width: 769px) {
  .main-nav__wrapper li a.nested.is-active:before {
    bottom: -8px;
  }
}

@keyframes triangle {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes line {
  from {
    left: 50%;
    width: 0%;
  }
  to {
    left: 0%;
    width: 100%;
  }
}

.logo {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1.4em;
  color: #ab9569;
  font-weight: 600;
}

@media (max-width: 769px) {
  .logo {
    align-items: center;
    margin-top: 15px;
  }
}

.logo img {
  width: 200px;
  height: 45px;
}

.logo span {
  display: block;
  margin-top: 5px;
}

@media (max-width: 769px) {
  .nested-nav-container {
    flex-direction: column;
  }
}

.nested-nav-wrapper {
  position: absolute;
  width: 135px;
  left: -20px;
  top: 39px;
  padding: 20px 0 20px 20px;
  box-shadow: 0px 8px 35px -16px rgba(0, 0, 0, 0.75);
  opacity: 0;
  z-index: -9;
  transition: .3s;
  background-color: #fff;
}

@media (max-width: 769px) {
  .nested-nav-wrapper {
    position: relative;
    display: none;
    opacity: 1;
    width: auto;
    box-shadow: unset;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 769px) and (max-width: 769px) {
  .nested-nav-wrapper {
    top: 0;
    left: 0;
    padding: 0;
  }
}

.nested-nav-wrapper.is-active {
  opacity: 1;
  z-index: 9;
}

@media (max-width: 769px) {
  .nested-nav-wrapper.is-active {
    display: flex;
    margin-top: 15px;
  }
}

.nested-nav-wrapper li {
  margin: 18px 0 0 0;
  width: fit-content;
}

@media (max-width: 769px) {
  .nested-nav-wrapper li {
    margin-top: 8px;
  }
  .nested-nav-wrapper li:after {
    content: '';
    position: absolute;
    top: calc(50% - 3px);
    left: -10px;
    width: 3px;
    height: 3px;
    background-color: #651932;
    border-radius: 50%;
  }
}

.nested-nav-wrapper li:first-child {
  margin-top: 2px;
}

@media (max-width: 769px) {
  .nested-nav-wrapper li a {
    font-size: 14px;
  }
}

.nested-nav-wrapper li a:after {
  bottom: -8px;
}

.main-nav {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .main-nav {
    height: 100px;
  }
}

@media (max-width: 769px) {
  .main-nav {
    width: 100%;
    height: 100%;
    background-color: #fff;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    right: -2000px;
    top: 0;
    transition: .3s ease-in-out;
  }
}

.main-nav.open {
  right: 0;
  z-index: 9999999;
}

.main-nav__wrapper {
  font-size: 1.6em;
  font-weight: bold;
  color: #651932;
  display: flex;
}

@media (max-width: 769px) {
  .main-nav__wrapper {
    flex-direction: column;
    margin-top: 20px;
  }
}

#hamburger {
  width: 18px;
  height: 18px;
  position: fixed;
  top: 15px;
  right: 15px;
  display: none;
  z-index: 9999;
}

@media (max-width: 769px) {
  #hamburger {
    display: block;
  }
}

#hamburger > div {
  display: block;
  width: 100%;
  height: 2px;
  background: #ab9569;
  position: absolute;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .3s ease-in-out;
}

#hamburger div:nth-child(1) {
  top: 0px;
}

#hamburger div:nth-child(2) {
  top: 6px;
}

#hamburger div:nth-child(3) {
  top: 12px;
}

#hamburger.open div:nth-child(1) {
  top: 6px;
  transform: rotate(135deg);
}

#hamburger.open div:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#hamburger.open div:nth-child(3) {
  top: 6px;
  transform: rotate(-135deg);
}

footer {
  background-color: #ab9569;
  color: #fff;
  font-size: 1.4em;
}

.footer-container {
  display: flex;
}

@media (max-width: 769px) {
  .footer-container {
    flex-direction: column;
  }
}

.footer-container > div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media (max-width: 769px) {
  .footer-container > div {
    margin-right: 0;
    width: 100%;
  }
}

.footer-container a {
  transition: .3s ease-in-out;
}

.footer-container a:hover {
  color: #22101d;
}

.footer-container__column-1, .footer-container__column-2 {
  width: 290px;
  margin-right: 30px;
}

.footer-container__column-3 {
  width: 450px;
  margin-right: 190px;
}

@media (max-width: 1200px) {
  .footer-container__column-3 {
    margin-right: 45px;
  }
}

.footer-container__column-4 {
  width: 160px;
}

.footer-container__column-5 {
  flex: 1;
}

@media (max-width: 769px) {
  .logo-footer {
    display: flex;
    justify-content: center;
  }
}

.logo-footer img {
  width: 200px;
  height: 45px;
  margin: 85px 0;
}

@media (max-width: 1600px) {
  .logo-footer img {
    margin: 65px 0;
  }
}

@media (max-width: 1200px) {
  .logo-footer img {
    margin: 40px 0;
  }
}

@media (max-width: 769px) {
  .logo-footer img {
    margin: 15px 0;
  }
}

.footer-container__column-5 svg {
  width: 40px;
}

.footer-container__column-5 svg:hover .st0, .footer-container__column-5 svg:hover .st1 {
  fill: #22101d;
}

.footer-container__top > span {
  display: block;
}

.footer-container__top .title-footer {
  font-weight: bold;
  margin-bottom: 20px;
}

@media (max-width: 769px) {
  .footer-container__top .title-footer {
    margin-bottom: 10px;
  }
}

.footer-container__bottom {
  height: 40px;
  margin: 210px 0 85px 0;
}

@media (max-width: 1600px) {
  .footer-container__bottom {
    margin: 180px 0 65px 0;
  }
}

@media (max-width: 1200px) {
  .footer-container__bottom {
    margin: 140px 0 40px 0;
  }
}

@media (max-width: 769px) {
  .footer-container__bottom {
    margin: 15px 0;
    height: auto;
  }
}

.social-media-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 25px 0 0 -3px;
}

@media (max-width: 769px) {
  .social-media-footer {
    width: 160px;
  }
}

.social-media-footer__item {
  display: block;
  max-width: 23px;
  height: 21px;
}

.social-media-footer__item:hover .st0, .social-media-footer__item:hover .st1 {
  fill: #22101d;
}

#awards {
  margin: 195px 0;
}

@media (max-width: 1600px) {
  #awards {
    margin: 145px 0;
  }
}

@media (max-width: 1200px) {
  #awards {
    margin: 95px 0;
  }
}

@media (max-width: 769px) {
  #awards {
    margin: 45px 0;
  }
}

#awards ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 160px;
}

@media (max-width: 1600px) {
  #awards ul {
    flex-wrap: wrap;
    padding: 0 80px;
  }
}

@media (max-width: 1200px) {
  #awards ul {
    padding: 0 40px;
  }
}

@media (max-width: 769px) {
  #awards ul {
    padding: 0;
  }
}

#awards ul li img {
  max-height: 50px;
}

#footer-event img {
  margin-right: 60px;
  max-width: 385px;
  max-height: 190px;
  width: 100%;
  height: 100%;
}

@media (max-width: 769px) {
  #footer-event img {
    margin: 0 0 30px 0;
  }
}

#footer-event h3 {
  font-size: 3em;
  font-weight: bold;
}

#footer-event p {
  font-size: 1.6em;
  font-style: italic;
  font-family: ratiomodern, serif;
  margin: 20px 0 35px 0;
}

#footer-event {
  padding: 30px;
  background: #f3f3f3;
  display: flex;
  margin: 0 160px;
}

@media (max-width: 1600px) {
  #footer-event {
    margin: 0 80px;
  }
}

@media (max-width: 1200px) {
  #footer-event {
    margin: 0 40px;
  }
}

@media (max-width: 769px) {
  #footer-event {
    margin: 0;
    flex-direction: column;
    padding: 15px;
  }
}

.last-element {
  margin-bottom: 185px;
}

@media (max-width: 1600px) {
  .last-element {
    margin-bottom: 145px;
  }
}

@media (max-width: 1200px) {
  .last-element {
    margin-bottom: 105px;
  }
}

@media (max-width: 769px) {
  .last-element {
    margin-bottom: 55px;
  }
}

#home-slider {
  position: relative;
  text-align: right;
  margin-bottom: 225px;
}

@media (max-width: 1600px) {
  #home-slider {
    margin-bottom: 135px;
  }
}

@media (max-width: 1200px) {
  #home-slider {
    margin-bottom: 85px;
  }
}

@media (max-width: 769px) {
  #home-slider {
    margin-bottom: 25px;
    text-align: left;
  }
}

#home-slider h2 {
  margin-bottom: 60px;
}

#home-slider p {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 25px;
}

@media (max-width: 1600px) {
  #home-slider p {
    font-size: 1.8em;
  }
}

@media (max-width: 1200px) {
  #home-slider p {
    font-size: 1.6em;
  }
}

@media (max-width: 769px) {
  #home-slider p {
    font-size: 1.4em;
  }
}

#home-slider .button {
  margin-left: auto;
}

@media (max-width: 769px) {
  #home-slider .button {
    margin-left: 0;
  }
}

#home-slider .line-swiper {
  top: 50%;
  width: calc(100% - 360px);
  transform: translate(160px, -50%);
}

#home-slider-container {
  width: 100%;
  height: 810px;
}

@media (max-width: 1600px) {
  #home-slider-container {
    height: 600px;
  }
}

@media (max-width: 1200px) {
  #home-slider-container {
    height: 450px;
  }
}

#home-slider-container .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-position: center;
}

@media (max-width: 769px) {
  #home-slider-container .swiper-slide {
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 25px;
  }
}

#home-slider-container .swiper-slide__content {
  margin-right: 160px;
  position: relative;
  top: -17px;
}

@media (max-width: 1600px) {
  #home-slider-container .swiper-slide__content {
    margin-right: 120px;
  }
}

@media (max-width: 1200px) {
  #home-slider-container .swiper-slide__content {
    margin-right: 80px;
  }
}

@media (max-width: 769px) {
  #home-slider-container .swiper-slide__content {
    margin-right: 0;
    margin-left: 15px;
  }
}

#home-slider-events-container .swiper-button-next, #home-slider-events-container .swiper-button-prev {
  width: auto;
  height: auto;
  position: relative;
  color: #22101d;
  margin: 0;
  left: 0;
  right: 0;
  font-size: 1.4em;
  transition: .3 ease-in-out;
}

#home-slider-events-container .swiper-button-next:hover, #home-slider-events-container .swiper-button-prev:hover {
  color: #ab9569;
}

#home-slider-events-container .swiper-button-next:after, #home-slider-events-container .swiper-button-prev:after {
  content: none;
}

#home-slider-events-container .home-slider-events-arrows-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px auto 20px auto;
}

@media (max-width: 1600px) {
  #home-slider-events-container .home-slider-events-arrows-container {
    margin-top: 80px;
  }
}

@media (max-width: 1200px) {
  #home-slider-events-container .home-slider-events-arrows-container {
    margin-top: 60px;
  }
}

@media (max-width: 769px) {
  #home-slider-events-container .home-slider-events-arrows-container {
    margin-top: 40px;
  }
}

#home-slider-events-container .home-slider-events-arrows-container__line {
  width: 30px;
  height: 1px;
  background-color: #22101d;
  margin: 0 15px;
}

.swiper-events-item {
  display: flex;
}

@media (max-width: 1200px) {
  .swiper-events-item {
    flex-direction: column-reverse;
  }
}

.swiper-events-item__photo {
  position: relative;
}

.swiper-events-item__photo img {
  width: 770px;
  height: 450px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 1600px) {
  .swiper-events-item__photo img {
    width: 570px;
    height: 400px;
  }
}

@media (max-width: 1200px) {
  .swiper-events-item__photo img {
    width: 100%;
    height: 400px;
  }
}

.swiper-events-item__photo--date {
  position: absolute;
  top: 25px;
  right: -65px;
}

@media (max-width: 1200px) {
  .swiper-events-item__photo--date {
    right: 30px;
    top: -25px;
  }
}

.swiper-events-item__photo--date > div {
  background-color: #ab9569;
  color: #fff;
  text-align: center;
  width: 130px;
}

.swiper-events-item__photo--date .date-top {
  font-family: ratiomodern, serif;
  font-size: 1.6em;
  padding: 35px 0;
}

@media (max-width: 1200px) {
  .swiper-events-item__photo--date .date-top {
    padding: 25px 0;
  }
}

.swiper-events-item__photo--date .date-top span {
  font-size: 60px;
}

.swiper-events-item__photo--date .date-bottom {
  margin-top: 1px;
  font-size: 1.4em;
  padding: 8px 0;
}

.swiper-events-item__date {
  margin: auto 30px 10px 30px;
  height: 100%;
  width: 130px;
  font-size: 1.8em;
}

@media (max-width: 1200px) {
  .swiper-events-item__date {
    margin: 15px 0;
  }
}

.swiper-events-item__date b {
  font-size: 12px;
  margin-right: 10px;
}

.swiper-events-item__content {
  padding-top: 100px;
}

@media (max-width: 1600px) {
  .swiper-events-item__content {
    padding-top: 70px;
  }
}

@media (max-width: 1200px) {
  .swiper-events-item__content {
    padding-top: 30px;
  }
}

@media (max-width: 769px) {
  .swiper-events-item__content {
    padding-top: 10px;
  }
}

.swiper-events-item__content h2 {
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 20px;
}

.swiper-events-item__content p {
  font-size: 1.6em;
}

.swiper-events-item__content .button {
  margin-top: 35px;
}

@media (max-width: 1200px) {
  .swiper-events-item__content .button {
    margin-top: 15px;
  }
}

#comments {
  margin: 85px auto 0 auto;
  min-height: 140px;
  width: 930px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media (max-width: 1200px) {
  #comments {
    width: 100%;
  }
}

#comments img {
  width: 66px;
  height: 44px;
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 1200px) {
  #comments img {
    top: -50px;
  }
}

#comments p {
  font-size: 2.4em;
  font-family: ratiomodern, serif;
  font-style: italic;
}

#comments span {
  font-size: 1.6em;
  display: block;
  margin-top: 30px;
  font-weight: bold;
}

#space-between-comments {
  height: 90px;
  width: 1px;
  background-color: #ab9569;
  margin: 110px auto 85px auto;
}

@media (max-width: 1600px) {
  #space-between-comments {
    margin: 90px auto 65px auto;
  }
}

@media (max-width: 1200px) {
  #space-between-comments {
    margin: 70px auto 45px auto;
  }
}

@media (max-width: 769px) {
  #space-between-comments {
    margin: 50px auto 25px auto;
  }
}

#slider-cards {
  width: 100%;
  height: 515px;
}

@media (max-width: 1600px) {
  #slider-cards {
    height: 455px;
  }
}

@media (max-width: 1200px) {
  #slider-cards {
    height: 415px;
  }
}

@media (max-width: 769px) {
  #slider-cards {
    height: 350px;
  }
}

#slider-cards .swiper-slide {
  padding: 0 160px 125px 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1600px) {
  #slider-cards .swiper-slide {
    padding-right: 120px;
  }
}

@media (max-width: 1200px) {
  #slider-cards .swiper-slide {
    padding-right: 80px;
  }
}

@media (max-width: 769px) {
  #slider-cards .swiper-slide {
    padding: 15px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.slider-menu-container .line {
  bottom: 90px;
  width: 770px;
  transform: translate(160px, -50%);
}

.home-text-under-swiper {
  margin: 70px 0 160px 160px;
  line-height: 1;
}

@media (max-width: 1600px) {
  .home-text-under-swiper {
    margin: 50px 0 120px 120px;
  }
}

@media (max-width: 1200px) {
  .home-text-under-swiper {
    margin: 30px 0 90px 90px;
  }
}

@media (max-width: 769px) {
  .home-text-under-swiper {
    margin: 40px 0 60px 0;
  }
}

.home-text-under-swiper h2 {
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 25px;
}

.home-text-under-swiper p {
  font-size: 2em;
  font-style: italic;
  font-family: ratiomodern, serif;
}

.menu-container {
  margin-bottom: 290px;
}

@media (max-width: 1600px) {
  .menu-container {
    margin-bottom: 200px;
  }
}

@media (max-width: 1200px) {
  .menu-container {
    margin-bottom: 100px;
  }
}

@media (max-width: 769px) {
  .menu-container {
    margin-bottom: 50px;
  }
}

.menu-container__row h3 {
  font-size: 3em;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 40px;
}

.menu-container__row h5 {
  font-size: 12px;
  color: #22101d;
  font-weight: 600;
  text-transform: uppercase;
}

.menu-container__row > div {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

@media (max-width: 769px) {
  .menu-container__row > div {
    margin-bottom: 50px;
  }
}

.menu-container__row--single {
  font-size: 2em;
  font-weight: bold;
  color: #ab9569;
  width: 450px;
  margin: 0 100px 25px 0;
}

@media (min-width: 1850px) {
  .menu-container__row--single:nth-child(3n+3) {
    margin-right: 0;
  }
}

@media (max-width: 1850px) {
  .menu-container__row--single:nth-child(2n+2) {
    margin-right: 0;
  }
}

@media (max-width: 1200px) {
  .menu-container__row--single {
    margin-right: 50px;
  }
}

@media (max-width: 769px) {
  .menu-container__row--single {
    margin-right: 0;
    width: 100%;
  }
}

.menu-container__row--single p {
  font-family: ratiomodern, serif;
  color: #22101d;
  font-weight: 500;
  font-style: italic;
  letter-spacing: -1px;
}

.menu-container__row--single p.room-desc {
  font-style: normal;
  margin-top: 25px;
  font-weight: normal;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
}

.menu-container__row--single > div {
  display: flex;
  align-items: center;
}

.menu-container__row--single > div.wine-title {
  flex-direction: column;
  align-items: flex-start;
  margin: 15px 0;
}

.menu-container__row--single > div.wine-title > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-container__row--single > div.drink-title {
  color: #22101d;
  font-size: 18px;
}

.menu-container__row--single > div.drink-title.only-price {
  justify-content: flex-end;
}

.menu-container__row--single > div.drink-title span {
  font-weight: 400;
}

.menu-container__row--single .dots {
  margin: 0 15px 0 25px;
  flex: 1;
  border-bottom: 1px dashed #ab9569;
}

.menu-container.drinks .menu-container__row {
  display: flex;
  flex-wrap: wrap;
}

.menu-container.drinks .menu-container__row > div {
  margin-right: 100px;
  flex-direction: column;
}

@media (min-width: 1850px) {
  .menu-container.drinks .menu-container__row > div:nth-child(3n+3) {
    margin-right: 0;
  }
}

@media (max-width: 1850px) {
  .menu-container.drinks .menu-container__row > div:nth-child(2n+2) {
    margin-right: 0;
  }
}

.menu-container.drinks .menu-container__row--single {
  margin-right: 0;
}

.sezon-menu {
  margin-bottom: 150px;
  padding-bottom: 100px;
  background-color: #f3f3f3;
  position: relative;
}

@media (max-width: 1600px) {
  .sezon-menu {
    margin-bottom: 120px;
    padding-bottom: 80px;
  }
}

@media (max-width: 1200px) {
  .sezon-menu {
    margin-bottom: 80px;
    padding-bottom: 60px;
  }
}

@media (max-width: 769px) {
  .sezon-menu {
    margin-bottom: 30px;
    padding-bottom: 10px;
  }
}

.sezon-menu__text {
  padding: 55px 0 15px 0;
  text-align: right;
  font-size: 1.6em;
  font-weight: 600;
  margin-right: 160px;
  position: relative;
}

@media (max-width: 1600px) {
  .sezon-menu__text {
    margin-right: 80px;
  }
}

@media (max-width: 1200px) {
  .sezon-menu__text {
    margin-right: 40px;
  }
}

@media (max-width: 769px) {
  .sezon-menu__text {
    margin-right: 15px;
    text-align: left;
    padding-top: 25px;
  }
}

.sezon-menu__text:after {
  content: '';
  width: 1px;
  height: 180px;
  background: #ab9569;
  position: absolute;
  right: -30px;
  bottom: 0;
}

.sezon-menu .menu-container__row > div {
  margin-bottom: 0;
}

.sezon-menu:after {
  content: '';
  width: 160px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -160px;
  background-color: #f3f3f3;
}

.sezon-menu:before {
  content: '';
  width: 160px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -160px;
  background-color: #f3f3f3;
}

.offer-container__content {
  padding-right: 60px;
}

@media (max-width: 769px) {
  .offer-container__content {
    padding-right: 0;
  }
}

.offer-container__content h1 {
  font-size: 4em;
  margin-bottom: 35px;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .offer-container__content h1 {
    margin-bottom: 25px;
  }
}

@media (max-width: 769px) {
  .offer-container__content h1 {
    margin-bottom: 15px;
  }
}

.offer-container__content span {
  font-family: ratiomodern, serif;
  font-style: italic;
  font-size: 1.6em;
}

.offer-container__content p {
  font-size: 1.6em;
  margin-top: 75px;
  max-width: 930px;
}

@media (max-width: 1200px) {
  .offer-container__content p {
    margin-top: 45px;
  }
}

@media (max-width: 769px) {
  .offer-container__content p {
    margin-top: 15px;
  }
}

.offer-container__content a {
  margin-top: 50px;
}

.download-container {
  display: flex;
  margin-top: 55px;
}

@media (max-width: 769px) {
  .download-container {
    flex-direction: column;
    margin-top: 25px;
  }
}

.download-container > div:first-child {
  margin-right: 30px;
}

@media (max-width: 769px) {
  .download-container > div:first-child {
    margin: 0 0 25px 0;
  }
}

.download-container > div span {
  font-style: normal;
  font-family: proxima-nova, sans-serif;
  font-weight: bold;
}

.download-container > div a {
  margin-top: 15px;
}

.offer-container__menu {
  margin-left: 90px;
  position: relative;
  width: 210px;
  flex-shrink: 0;
}

@media (max-width: 1600px) {
  .offer-container__menu {
    margin-left: 60px;
  }
}

@media (max-width: 1200px) {
  .offer-container__menu {
    margin-left: 40px;
  }
}

@media (max-width: 769px) {
  .offer-container__menu {
    margin: 30px 0 0 0;
    border-top: 1px solid #ab9569;
    padding-top: 30px;
  }
}

.offer-container__menu span {
  font-size: 1.2em;
  font-weight: bold;
  text-transform: uppercase;
}

.offer-container__menu ul {
  margin-top: 20px;
}

.offer-container__menu ul li {
  margin-top: 10px;
}

.offer-container__menu ul li a {
  transition: .3s ease-in-out;
  font-size: 2em;
}

.offer-container__menu ul li a:hover {
  color: #ab9569;
}

.offer-container__menu ul li a.is-active {
  color: #ab9569;
}

.offer-container__menu:after {
  content: '';
  width: 1px;
  height: 500px;
  background: #ab9569;
  position: absolute;
  top: -215px;
  left: -90px;
  z-index: 99;
}

@media (max-width: 1600px) {
  .offer-container__menu:after {
    left: -60px;
  }
}

@media (max-width: 1200px) {
  .offer-container__menu:after {
    left: -40px;
  }
}

@media (max-width: 769px) {
  .offer-container__menu:after {
    display: none;
  }
}

.offer-container {
  padding: 115px 160px 0 160px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1600px) {
  .offer-container {
    padding: 95px 80px 0 80px;
  }
}

@media (max-width: 1200px) {
  .offer-container {
    padding: 75px 40px 0 40px;
  }
}

@media (max-width: 769px) {
  .offer-container {
    padding: 45px 0 0 0;
    flex-direction: column;
  }
}

.rooms {
  margin: 130px 0 100px 0;
}

@media (max-width: 1600px) {
  .rooms {
    margin: 90px 0 70px 0;
  }
}

@media (max-width: 1200px) {
  .rooms {
    margin: 50px 0 30px 0;
  }
}

@media (max-width: 769px) {
  .rooms {
    margin: 30px 0;
  }
}

.rooms h3 {
  font-size: 4em;
  margin-bottom: 90px;
  font-weight: bold;
  padding-left: 160px;
}

@media (max-width: 1600px) {
  .rooms h3 {
    padding-left: 80px;
  }
}

@media (max-width: 1200px) {
  .rooms h3 {
    margin-bottom: 45px;
    padding-left: 40px;
  }
}

@media (max-width: 769px) {
  .rooms h3 {
    margin-bottom: 15px;
    padding-left: 0;
  }
}

.rooms__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rooms__list--single {
  margin-right: 50px;
}

@media (max-width: 1200px) {
  .rooms__list--single {
    margin-right: 0;
  }
}

@media (max-width: 769px) {
  .rooms__list--single {
    margin-top: 30px;
  }
}

.rooms__list--single:last-child {
  margin-right: 0;
}

@media (max-width: 769px) {
  .rooms__list--single:first-child {
    margin-top: 0;
  }
}

.rooms__list--single > div:first-child {
  display: flex;
  align-items: flex-end;
}

.rooms__list--single > div h4 {
  font-size: 2.4em;
  margin-top: 25px;
  font-weight: bold;
  color: #ab9569;
}

@media (max-width: 1200px) {
  .rooms__list--single > div h4 {
    margin-top: 10px;
  }
}

.rooms__list--single > div p {
  font-size: 1.6em;
  margin-bottom: 5px;
}

.rooms__list--single > div img {
  margin-top: 25px;
  max-width: 250px;
  max-height: 165ox;
  width: 100%;
  height: 100%;
}

@media (max-width: 1200px) {
  .rooms__list--single > div img {
    margin-top: 10px;
  }
}

.rooms-number {
  font-size: 6em;
  font-family: ratiomodern, serif;
  color: #ab9569;
  display: block;
}

.rooms-line {
  width: 210px;
  height: 1px;
  background: #ab9569;
  display: block;
  margin: 0 0 15px 25px;
}

.offer-photos {
  display: flex;
  flex-wrap: wrap;
  padding: 120px 140px 0 160px;
}

@media (max-width: 1600px) {
  .offer-photos {
    padding: 80px 0 0 80px;
  }
}

@media (max-width: 1200px) {
  .offer-photos {
    padding: 50px 0 0 50px;
  }
}

@media (max-width: 769px) {
  .offer-photos {
    padding: 30px 0 0 0;
  }
}

.offer-photos.catering {
  margin: 30px 0 85px 0;
}

@media (max-width: 1600px) {
  .offer-photos.catering {
    margin: 20px 0 65px 0;
  }
}

@media (max-width: 1200px) {
  .offer-photos.catering {
    margin: 10px 0 45px 0;
  }
}

@media (max-width: 769px) {
  .offer-photos.catering {
    margin-bottom: 25px;
  }
}

.offer-photos > div {
  width: 100%;
}

.offer-photos img {
  max-width: 623px;
  width: 100%;
  height: 405px;
  margin-top: 30px;
}

.offer-photos img:first-child {
  margin-right: 30px;
}

@media (max-width: 769px) {
  .offer-photos img:first-child {
    margin-right: 0;
  }
}

@media (max-width: 1900px) {
  .offer-photos img {
    max-width: 523px;
  }
}

@media (max-width: 769px) {
  .offer-photos img {
    max-width: auto;
  }
}

.offer-photos__middle {
  display: flex;
}

@media (max-width: 1900px) {
  .offer-photos__middle {
    flex-wrap: wrap;
  }
}

.offer-photos__middle img {
  max-width: 450px;
  height: 595px;
}

.offer-photos__middle--top {
  margin-left: 30px;
}

@media (max-width: 1900px) {
  .offer-photos__middle--top {
    margin-left: 0;
  }
}

.offer-photos__middle--top img {
  max-width: 320px;
  height: 205px;
  margin-right: 0 !important;
}

@media (max-width: 1900px) {
  .offer-photos__middle--top img {
    margin-right: 30px !important;
    height: 354px;
  }
}

.offer-photos__middle--top img:last-child {
  margin-top: 30px;
  height: 354px;
}

.offer-photos__bottom {
  width: 100%;
}

.offer-photos__bottom img {
  height: 670px;
  max-width: 100%;
}

#comments.offer-container__comment {
  margin-top: 185px;
}

@media (max-width: 1600px) {
  #comments.offer-container__comment {
    margin-top: 145px;
  }
}

@media (max-width: 1200px) {
  #comments.offer-container__comment {
    margin-top: 105px;
  }
}

@media (max-width: 769px) {
  #comments.offer-container__comment {
    margin-top: 85px;
  }
}

#comments.offer-container__comment p {
  text-align: center;
}

#comments.offer-container__comment img {
  left: -50px;
}

@media (max-width: 1200px) {
  #comments.offer-container__comment img {
    left: 0;
    top: -60px;
  }
}

.swiper-container-comment {
  width: 930px;
}

@media (max-width: 1200px) {
  .swiper-container-comment {
    width: 100%;
  }
}

.swiper-container-comment .swiper-slide__content {
  width: 930px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {
  .swiper-container-comment .swiper-slide__content {
    width: 100%;
  }
}

.rooms-photos-container.wedding {
  margin: 115px 0;
}

@media (max-width: 1600px) {
  .rooms-photos-container.wedding {
    margin: 95px 0;
  }
}

@media (max-width: 1200px) {
  .rooms-photos-container.wedding {
    margin: 65px 0;
  }
}

@media (max-width: 769px) {
  .rooms-photos-container.wedding {
    margin: 35px 0;
  }
}

.rooms-photos-container.wedding .row-3 {
  height: 295px;
  display: grid;
  grid-template-columns: 1.1fr 1.1fr 0.8fr;
  grid-template-rows: 1fr;
  gap: 0px 30px;
  grid-template-areas: ". . .";
}

@media (max-width: 769px) {
  .rooms-photos-container.wedding .row-3 {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}

.rooms-photos-container.wedding .row-4 {
  display: grid;
  grid-template-columns: 1.1fr 1.1fr;
  grid-template-rows: 1fr;
  gap: 0px 30px;
  grid-template-areas: ". .";
}

@media (max-width: 769px) {
  .rooms-photos-container.wedding .row-4 {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}

.rooms-photos-container.wedding .row-5 {
  display: grid;
  grid-template-columns: 1.4fr 0.8fr;
  grid-template-rows: 1fr;
  gap: 0px 30px;
  grid-template-areas: ". .";
  height: auto;
}

@media (max-width: 769px) {
  .rooms-photos-container.wedding .row-5 {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}

.rooms-photos-container.wedding .row-5 img {
  max-height: none;
}

.rooms-photos-container.wedding .row-5 div {
  display: flex;
  flex-direction: column;
}

.rooms-photos-container.wedding .row-5 div img {
  max-height: none;
}

.rooms-photos-container.wedding .row-5 div img:first-child {
  margin-bottom: 30px;
}

.select-faq-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1px 30px;
  grid-template-areas: ". .";
  height: auto;
  margin: 0 160px 30px 160px;
}

@media (max-width: 1600px) {
  .select-faq-container {
    margin: 0 80px 30px 80px;
  }
}

@media (max-width: 1200px) {
  .select-faq-container {
    margin: 0 40px 20px 40px;
  }
}

@media (max-width: 769px) {
  .select-faq-container {
    margin: 0 0 20px 0;
    height: auto;
    display: flex;
    flex-direction: column;
  }
}

.select-faq-container > div {
  padding: 30px;
  background-color: #f3f3f3;
  transition: .3s ease-in-out;
}

@media (max-width: 769px) {
  .select-faq-container > div:first-child {
    margin-bottom: 30px;
  }
}

.select-faq-container > div:hover {
  color: #fff;
  background-color: #ab9569;
}

.select-faq-container > div:hover a {
  border-color: #fff;
  color: #fff;
}

.select-faq-container > div img {
  max-height: 220px;
  width: 100%;
}

.select-faq-container > div h4 {
  margin: 35px 0px;
  font-size: 30px;
  font-weight: bold;
}

.select-faq-container > div p {
  font-style: italic;
  font-size: 16px;
  font-family: ratiomodern, serif;
}

.select-faq-container > div a {
  margin-top: 45px;
  color: #ab9569;
}

.contact-container__content h2 {
  font-size: 4em;
  margin-bottom: 35px;
  font-weight: bold;
  line-height: 1em;
}

@media (max-width: 1200px) {
  .contact-container__content h2 {
    margin-bottom: 25px;
  }
}

@media (max-width: 769px) {
  .contact-container__content h2 {
    margin-bottom: 15px;
  }
}

.contact-container__content p {
  font-family: ratiomodern, serif;
  font-style: italic;
  font-size: 1.6em;
}

#map {
  width: 100%;
  height: 515px;
  margin-bottom: 100px;
}

@media (max-width: 1200px) {
  #map {
    margin-bottom: 60px;
  }
}

@media (max-width: 1200px) {
  #map {
    margin-bottom: 30px;
  }
}

.contact-container {
  padding: 115px 0 0 160px;
  margin-bottom: 170px;
}

@media (max-width: 1600px) {
  .contact-container {
    padding: 95px 0 0 80px;
    margin-bottom: 120px;
  }
}

@media (max-width: 1200px) {
  .contact-container {
    padding: 75px 0 0 40px;
    margin-bottom: 70px;
  }
}

@media (max-width: 769px) {
  .contact-container {
    padding: 45px 0 0 0;
    margin-bottom: 20px;
  }
}

.contact-container__info {
  margin-top: 80px;
  display: flex;
  align-items: flex-end;
}

@media (max-width: 1600px) {
  .contact-container__info {
    margin-top: 60px;
  }
}

@media (max-width: 1200px) {
  .contact-container__info {
    margin-top: 40px;
  }
}

@media (max-width: 769px) {
  .contact-container__info {
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.contact-container__info > div:first-child {
  margin-right: 230px;
}

@media (max-width: 1600px) {
  .contact-container__info > div:first-child {
    margin-right: 150px;
  }
}

@media (max-width: 1200px) {
  .contact-container__info > div:first-child {
    margin-right: 60px;
  }
}

@media (max-width: 769px) {
  .contact-container__info > div:first-child {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.contact-container__info > div:last-child {
  margin-left: 30px;
}

@media (max-width: 769px) {
  .contact-container__info > div:last-child {
    margin-left: 0;
    margin-top: 30px;
  }
}

.contact-container__info > div h3 {
  color: #ab9569;
  font-size: 2.4em;
  font-weight: bold;
}

.contact-container__info > div > div {
  font-size: 16px;
  display: flex;
  margin-top: 15px;
}

.contact-container__info > div > div span {
  display: block;
  width: 55px;
  margin-right: 15px;
}

.contact-container__info > div a {
  transition: .3s ease-in-out;
}

.contact-container__info > div a:hover {
  color: #ab9569;
}

.contact-container__info--title {
  font-size: 2em;
  font-weight: 600;
}

.events-header {
  margin: 115px 0 150px 160px;
}

@media (max-width: 1600px) {
  .events-header {
    margin: 85px 0 110px 80px;
  }
}

@media (max-width: 1200px) {
  .events-header {
    margin: 45px 0 70px 40px;
  }
}

@media (max-width: 769px) {
  .events-header {
    margin: 15px 0 30px 0;
  }
}

.events-header h1 {
  font-size: 6em;
  font-family: ratiomodern, serif;
  text-transform: uppercase;
  padding-left: 160px;
  font-weight: 400;
  line-height: 1;
}

@media (max-width: 1600px) {
  .events-header h1 {
    padding-left: 80px;
  }
}

@media (max-width: 1200px) {
  .events-header h1 {
    padding-left: 40px;
  }
}

@media (max-width: 769px) {
  .events-header h1 {
    padding-left: 0;
  }
}

.events-header h2 {
  font-size: 4em;
  padding-left: 160px;
  font-weight: bold;
  line-height: 1;
  margin-top: 15px;
}

@media (max-width: 1600px) {
  .events-header h2 {
    padding-left: 80px;
  }
}

@media (max-width: 1200px) {
  .events-header h2 {
    padding-left: 40px;
  }
}

@media (max-width: 769px) {
  .events-header h2 {
    padding-left: 0;
  }
}

.events-header .line {
  margin: 40px 0;
}

@media (max-width: 1600px) {
  .events-header .line {
    margin: 30px;
  }
}

@media (max-width: 1200px) {
  .events-header .line {
    margin: 20px;
  }
}

@media (max-width: 769px) {
  .events-header .line {
    margin: 10px 0;
  }
}

.events-header p {
  font-size: 2em;
  padding-left: 160px;
}

@media (max-width: 1600px) {
  .events-header p {
    padding-left: 80px;
  }
}

@media (max-width: 1200px) {
  .events-header p {
    padding-left: 40px;
  }
}

@media (max-width: 769px) {
  .events-header p {
    padding-left: 0;
  }
}

.events-list .line {
  margin: 80px 0 0 auto;
  width: calc(100% - 480px);
}

@media (max-width: 1600px) {
  .events-list .line {
    margin: 60px 0 0 auto;
    width: calc(100% - 280px);
  }
}

@media (max-width: 1200px) {
  .events-list .line {
    margin: 40px 0 0 auto;
    width: calc(100% - 140px);
  }
}

@media (max-width: 769px) {
  .events-list .line {
    margin: 20px 0 0 auto;
  }
}

.events-list__item {
  margin-bottom: 80px;
}

@media (max-width: 1600px) {
  .events-list__item {
    margin-bottom: 60px;
  }
}

@media (max-width: 1200px) {
  .events-list__item {
    margin-bottom: 40px;
  }
}

@media (max-width: 769px) {
  .events-list__item {
    margin-bottom: 20px;
  }
}

.events-list__item:last-child {
  margin-bottom: 0;
}

.events-list__item p {
  font-style: italic;
  font-family: ratiomodern, serif;
}

.events-list__item .line-item {
  width: 450px;
  height: 1px;
  background: #ab9569;
  position: relative;
  top: -20px;
  right: 320px;
}

@media (max-width: 1200px) {
  .events-list__item .line-item {
    display: none;
  }
}

.events-empty {
  font-size: 2em;
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
}

.events-list-previous {
  padding: 0 160px;
  margin-top: 200px;
}

@media (max-width: 1600px) {
  .events-list-previous {
    padding: 0 80px;
    margin-top: 100px;
  }
}

@media (max-width: 1200px) {
  .events-list-previous {
    padding: 0 40px;
    margin-top: 50px;
  }
}

@media (max-width: 769px) {
  .events-list-previous {
    padding: 0;
    margin-top: 30px;
  }
}

.events-list-previous h2 {
  font-size: 6em;
  font-family: ratiomodern, serif;
  text-transform: uppercase;
  padding-left: 160px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 75px;
}

@media (max-width: 1600px) {
  .events-list-previous h2 {
    padding-left: 80px;
    margin-bottom: 45px;
  }
}

@media (max-width: 1200px) {
  .events-list-previous h2 {
    padding-left: 40px;
    margin-bottom: 25px;
  }
}

@media (max-width: 769px) {
  .events-list-previous h2 {
    padding-left: 0;
  }
}

.events-list-previous__list--item {
  display: flex;
  padding: 15px 30px;
  background: #f3f3f3;
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .events-list-previous__list--item {
    margin-bottom: 20px;
  }
}

@media (max-width: 769px) {
  .events-list-previous__list--item {
    padding: 10px 20px;
  }
}

.events-list-previous__list--item .date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #8a8188;
  padding-right: 30px;
  font-family: ratiomodern, serif;
  color: #a19ea0;
  font-size: 16px;
}

@media (max-width: 769px) {
  .events-list-previous__list--item .date {
    padding-right: 20px;
  }
}

.events-list-previous__list--item .date__top {
  font-size: 60px;
}

.events-list-previous__list--item .info {
  padding-left: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #60545d;
}

@media (max-width: 769px) {
  .events-list-previous__list--item .info {
    padding-left: 15px;
  }
}

.events-list-previous__list--item .info h3 {
  font-weight: bold;
  font-size: 3em;
  margin-bottom: 20px;
}

.events-list-previous__list--item .info p {
  font-size: 1.6em;
  font-style: italic;
}

.single-event-container__content {
  padding: 0 160px 0 95px;
}

@media (max-width: 1600px) {
  .single-event-container__content {
    padding: 0 80px;
  }
}

@media (max-width: 1200px) {
  .single-event-container__content {
    padding: 0 40px;
  }
}

@media (max-width: 769px) {
  .single-event-container__content {
    padding: 0;
  }
}

.single-event-container__content h1 {
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 30px;
}

@media (max-width: 769px) {
  .single-event-container__content h1 {
    margin-bottom: 15px;
  }
}

.single-event-container__content p {
  font-size: 1.6em;
  font-weight: 600;
}

.single-event-container__content .first-paragraph {
  font-weight: 500;
  font-size: 2em;
  font-family: ratiomodern, serif;
  font-style: italic;
  margin-bottom: 80px;
}

@media (max-width: 769px) {
  .single-event-container__content .first-paragraph {
    margin-bottom: 40px;
  }
}

.single-event-container__content .last-paragraph {
  margin-bottom: 60px;
}

@media (max-width: 769px) {
  .single-event-container__content .last-paragraph {
    margin-bottom: 30px;
  }
}

.single-event-container .buy-ticket {
  padding-top: 90px;
  position: relative;
}

@media (max-width: 1200px) {
  .single-event-container .buy-ticket {
    padding-top: 60px;
  }
}

@media (max-width: 769px) {
  .single-event-container .buy-ticket {
    padding-top: 30px;
  }
}

.single-event-container .buy-ticket:after {
  content: '';
  position: absolute;
  top: 0;
  left: -96px;
  width: 1px;
  height: 100%;
  background: #fff;
  z-index: 999;
}

.single-event-container .buy-ticket h3 {
  margin-bottom: 25px;
  font-size: 3.2em;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
}

.single-event-container__info {
  padding: 0 95px 125px 160px;
  position: relative;
}

@media (max-width: 1600px) {
  .single-event-container__info {
    padding-left: 80px;
  }
}

@media (max-width: 1200px) {
  .single-event-container__info {
    padding-left: 40px;
  }
}

@media (max-width: 769px) {
  .single-event-container__info {
    padding: 0 0 15px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid #ab9569;
    border-bottom: 1px solid #ab9569;
    margin-top: 30px;
  }
}

.single-event-container__info:after {
  content: '';
  position: absolute;
  top: -200px;
  right: 0;
  width: 1px;
  height: calc(100% + 200px);
  background: #ab9569;
  z-index: 99;
}

@media (max-width: 769px) {
  .single-event-container__info:after {
    display: none;
  }
}

.single-event-container__info > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.single-event-container__info .date {
  align-items: center;
  height: 155px;
  border-bottom: 1px solid #ab9569;
  font-family: ratiomodern, serif;
  font-size: 16px;
  color: #ab9569;
}

@media (max-width: 769px) {
  .single-event-container__info .date {
    border: unset;
  }
}

.single-event-container__info .date__top {
  font-size: 60px;
}

.single-event-container__info .hour, .single-event-container__info .ticket {
  margin-top: 65px;
}

@media (max-width: 769px) {
  .single-event-container__info .hour, .single-event-container__info .ticket {
    margin-top: 0;
  }
}

.single-event-container__info .hour span, .single-event-container__info .ticket span {
  font-size: 1.8em;
}

.single-event-container__info .hour b, .single-event-container__info .ticket b {
  font-size: 12px;
  font-weight: bold;
}

.single-event-container__info .ticket {
  margin: 25px 0 60px 0;
}

.single-event-container {
  display: flex;
  padding-top: 115px;
}

@media (max-width: 1600px) {
  .single-event-container {
    padding-top: 85px;
  }
}

@media (max-width: 1200px) {
  .single-event-container {
    padding-top: 45px;
  }
}

@media (max-width: 769px) {
  .single-event-container {
    padding-top: 25px;
    flex-direction: column-reverse;
  }
}

.about-us__menu {
  display: flex;
  align-items: center;
}

@media (max-width: 769px) {
  .about-us__menu {
    flex-direction: column;
    align-items: flex-start;
  }
}

.about-us__menu--left {
  position: relative;
}

.about-us__menu--left img {
  max-width: 610px;
  max-height: 435px;
  height: 100%;
}

@media (max-width: 769px) {
  .about-us__menu--left img {
    width: 100%;
  }
}

.about-us__menu--left h3 {
  font-size: 3.2em;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  right: -160px;
  top: 50px;
  text-transform: uppercase;
}

@media (max-width: 1600px) {
  .about-us__menu--left h3 {
    right: -100px;
  }
}

@media (max-width: 769px) {
  .about-us__menu--left h3 {
    position: relative;
    right: auto;
    top: auto;
    margin-bottom: 20px;
  }
}

.about-us__menu--line {
  position: absolute;
  right: -160px;
  bottom: 100px;
  width: 450px;
  height: 1px;
  background: #ab9569;
}

@media (max-width: 1600px) {
  .about-us__menu--line {
    right: -100px;
  }
}

@media (max-width: 769px) {
  .about-us__menu--line {
    display: none;
  }
}

.about-us__menu p {
  font-size: 2em;
  font-style: italic;
  margin-bottom: 30px;
  width: 610px;
  font-family: ratiomodern, serif;
}

@media (max-width: 1600px) {
  .about-us__menu p {
    width: 100%;
  }
}

.about-us__menu--right {
  margin-left: 190px;
}

@media (max-width: 1600px) {
  .about-us__menu--right {
    margin-left: 130px;
  }
}

@media (max-width: 769px) {
  .about-us__menu--right {
    margin: 30px 0 0 0;
  }
}

.about-us-slider {
  margin-bottom: 0 !important;
}

.about-us-slider .swiper-slide {
  background-repeat: no-repeat;
  background-position: left !important;
}

.about-us-slider .swiper-slide__content p {
  margin-bottom: -20px !important;
}

.about-us-slider .swiper-slide__content span {
  display: block;
  margin-top: 20px;
  position: relative;
  top: 60px;
  font-size: 16px;
}

.about-us-slider .swiper-slide__content span b {
  font-size: 20px;
}

.about-us__content {
  margin: 120px 0 165px 160px;
  width: 930px;
}

@media (max-width: 1600px) {
  .about-us__content {
    margin: 90px 0 125px 80px;
  }
}

@media (max-width: 1200px) {
  .about-us__content {
    width: 100%;
    margin: 60px 0 80px 40px;
  }
}

@media (max-width: 769px) {
  .about-us__content {
    margin: 30px 0 40px 0;
  }
}

.about-us__content h2 {
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 20px;
}

@media (max-width: 1200px) {
  .about-us__content h2 {
    margin-bottom: 10px;
  }
}

.about-us__content span {
  font-family: ratiomodern, serif;
  font-style: italic;
  font-size: 2em;
  display: block;
  margin-bottom: 95px;
}

@media (max-width: 1200px) {
  .about-us__content span {
    margin-bottom: 65px;
  }
}

@media (max-width: 769px) {
  .about-us__content span {
    margin-bottom: 35px;
  }
}

.about-us__content p {
  font-size: 1.6em;
  margin-bottom: 45px;
}

@media (max-width: 1200px) {
  .about-us__content p {
    margin-bottom: 25px;
  }
}

.about-us__content > div {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 769px) {
  .about-us__content > div {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.about-us__content > div a {
  margin-left: 30px;
}

@media (max-width: 769px) {
  .about-us__content > div a {
    margin-left: 0;
  }
  .about-us__content > div a:last-child {
    margin-top: 20px;
  }
}

.rewards-container__header {
  display: flex;
  justify-content: space-between;
  padding: 0 160px;
  margin-bottom: 55px;
}

@media (max-width: 1600px) {
  .rewards-container__header {
    padding: 80px;
  }
}

@media (max-width: 1200px) {
  .rewards-container__header {
    padding: 40px;
  }
}

@media (max-width: 769px) {
  .rewards-container__header {
    padding: 0;
    flex-direction: column;
  }
}

.rewards-container__header img {
  max-width: 610px;
  max-height: 435px;
  height: 100%;
}

@media (max-width: 769px) {
  .rewards-container__header img {
    width: 100%;
  }
}

.rewards-container__header > div {
  padding-top: 90px;
  width: 550px;
  margin-right: 50px;
}

@media (max-width: 1200px) {
  .rewards-container__header > div {
    padding-top: 40px;
  }
}

@media (max-width: 769px) {
  .rewards-container__header > div {
    width: 100%;
    margin: 0;
    padding-top: 10px;
  }
}

.rewards-container__header > div h2 {
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 20px;
}

.rewards-container__header > div p {
  font-size: 2em;
  font-family: ratiomodern, serif;
  font-style: italic;
}

@media (max-width: 769px) {
  .rewards-container__header > div p {
    margin-bottom: 30px;
  }
}

.rewards-container__list {
  margin-bottom: 80px;
}

@media (max-width: 1600px) {
  .rewards-container__list {
    margin-bottom: 60px;
  }
}

@media (max-width: 1200px) {
  .rewards-container__list {
    margin-bottom: 40px;
  }
}

@media (max-width: 769px) {
  .rewards-container__list {
    margin-bottom: 20px;
  }
}

.rewards-container__list--header {
  display: flex;
  align-items: flex-end;
}

.rewards-container__list--header h3 {
  margin-left: auto;
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 30px;
}

@media (max-width: 769px) {
  .rewards-container__list--header h3 {
    margin-bottom: 20px;
  }
}

.rewards-container__list--line {
  width: 130px;
  height: 1px;
  background: #000;
  margin-bottom: 4px;
}

@media (max-width: 769px) {
  .rewards-container__list--line {
    margin-bottom: 24px;
  }
}

.rewards-container__list--items {
  display: flex;
  flex-wrap: wrap;
}

.rewards-item {
  width: 380px;
  margin: 0 100px 50px 0;
}

@media (max-width: 769px) {
  .rewards-item {
    width: 300px;
    margin: 0 15px 30px 0;
  }
}

.rewards-item__line {
  margin: 25px 0;
  width: 130px;
  height: 1px;
  background: #ab9569;
}

@media (max-width: 769px) {
  .rewards-item__line {
    margin: 15px 0;
  }
}

.rewards-item h3 {
  font-size: 2.4em;
  font-weight: bold;
  margin-bottom: 15px;
}

.rewards-item p {
  font-size: 1.6em;
}

.faq-container {
  margin-top: 75px;
  padding: 0 160px;
}

@media (max-width: 1600px) {
  .faq-container {
    padding: 0 80px;
    margin-top: 55px;
  }
}

@media (max-width: 1200px) {
  .faq-container {
    padding: 0 40px;
    margin-top: 35px;
  }
}

@media (max-width: 769px) {
  .faq-container {
    padding: 0;
    margin-top: 25px;
  }
}

.faq-container__content {
  padding-top: 20px;
  margin-left: 225px;
  width: 100%;
  z-index: 1;
}

@media (max-width: 1600px) {
  .faq-container__content {
    margin-left: 185px;
  }
}

@media (max-width: 1200px) {
  .faq-container__content {
    margin-left: 145px;
  }
}

@media (max-width: 769px) {
  .faq-container__content {
    margin-left: 0;
  }
}

.faq-container__content.hide {
  position: absolute;
  left: -99999px;
}

.faq-container__content.is-nav-fixed {
  margin-left: 475px;
}

.faq-container__header {
  display: block;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.faq-container nav {
  width: 250px;
}

.faq-container nav .title {
  font-weight: 600;
  font-size: 1.4em;
  display: block;
  margin-bottom: 5px;
}

.faq-container nav ul {
  margin-bottom: 26px;
}

.faq-container nav li span {
  font-size: 1.8em;
  width: fit-content;
  margin-bottom: 5px;
  cursor: pointer;
  background: #fff;
  padding-right: 25px;
  position: relative;
}

.faq-container nav li span:after {
  content: '';
  position: absolute;
  opacity: 0;
  bottom: 0;
  left: 0;
  width: calc(100% - 25px);
  height: 1px;
  background: #ab9569;
}

.faq-container nav li span.is-active {
  color: #ab9569;
}

.faq-container nav li span.is-active:after {
  opacity: 1;
}

.faq-container__wrapper {
  display: flex;
}

@media (max-width: 769px) {
  .faq-container__wrapper {
    flex-direction: column;
  }
}

.faq-container__wrapper > div:first-child {
  z-index: 9;
}

.faq-container__content--item h3 {
  font-size: 1.2em;
  color: #ab9569;
  margin-bottom: 35px;
  position: relative;
}

@media (max-width: 769px) {
  .faq-container__content--item h3 {
    margin-bottom: 15px;
  }
}

.faq-container__content--item h3.is-active:after {
  width: calc(100% + 475px);
}

@media (max-width: 1600px) {
  .faq-container__content--item h3.is-active:after {
    width: calc(100%  + 395px);
  }
}

.faq-container__content--item h3:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 1px;
  width: calc(100% + 225px);
  background: #ab9569;
}

.faq-container__content--item li {
  font-size: 1.6em;
  margin-bottom: 5px;
}

.faq-container__content--item li:last-child {
  margin-bottom: 65px;
}

@media (max-width: 1600px) {
  .faq-container__content--item li:last-child {
    margin-bottom: 45px;
  }
}

@media (max-width: 1200px) {
  .faq-container__content--item li:last-child {
    margin-bottom: 35px;
  }
}

@media (max-width: 769px) {
  .faq-container__content--item li:last-child {
    margin-bottom: 25px;
  }
}

.faq-container__content--item .ask {
  font-weight: bold;
}

.room-desc-container {
  display: flex;
  flex-direction: column;
}

.rooms-photos-container {
  padding: 0 160px;
  margin-bottom: 110px;
}

@media (max-width: 1600px) {
  .rooms-photos-container {
    padding: 0 80px;
    margin-bottom: 60px;
  }
}

@media (max-width: 1200px) {
  .rooms-photos-container {
    padding: 0 40px;
    margin-bottom: 40px;
  }
}

@media (max-width: 769px) {
  .rooms-photos-container {
    padding: 0;
    margin-bottom: 10px;
  }
}

.rooms-photos-container img {
  width: 100%;
  height: 100%;
  max-height: 510px;
}

@media (max-width: 1600px) {
  .rooms-photos-container img {
    max-height: 460px;
  }
}

@media (max-width: 1200px) {
  .rooms-photos-container img {
    max-height: 410px;
  }
}

@media (max-width: 769px) {
  .rooms-photos-container img {
    margin-bottom: 30px;
    max-height: 300px;
  }
}

.rooms-photos-container > div {
  height: 510px;
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .rooms-photos-container > div {
    height: 460px;
  }
}

@media (max-width: 1200px) {
  .rooms-photos-container > div {
    height: 410px;
  }
}

@media (max-width: 769px) {
  .rooms-photos-container > div {
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
}

.row-1 {
  display: grid;
  grid-template-columns: 1.4fr 0.6fr;
  grid-template-rows: 1fr;
  gap: 0px 30px;
  grid-template-areas: ". .";
}

.row-2 {
  display: grid;
  grid-template-columns: 0.4fr 1.6fr;
  grid-template-rows: 1fr;
  gap: 0px 30px;
  grid-template-areas: ". .";
}

.rooms-container {
  margin: 140px 0 130px 0;
}

@media (max-width: 1600px) {
  .rooms-container {
    margin: 110px 0 90px 0;
  }
}

@media (max-width: 1200px) {
  .rooms-container {
    margin: 60px 0;
  }
}

@media (max-width: 769px) {
  .rooms-container {
    margin: 30px 0;
  }
}

.rooms-container .menu-container__row--single {
  margin-bottom: 45px;
}

@media (max-width: 769px) {
  .rooms-container .menu-container__row--single:last-child {
    margin-bottom: 5px;
  }
}

.ticket-container-buy {
  padding: 25px 160px 30px 160px;
  display: flex;
}

@media (max-width: 1900px) {
  .ticket-container-buy {
    padding: 25px 80px 30px 80px;
  }
}

@media (max-width: 1200px) {
  .ticket-container-buy {
    padding: 25px 40px 30px 40px;
  }
}

@media (max-width: 769px) {
  .ticket-container-buy {
    padding: 0;
    flex-direction: column;
  }
}

.ticket-container-buy__price {
  padding: 35px 95px 115px 25px;
  border-right: 1px solid #ab9569;
  display: flex;
  flex-direction: column;
  position: relative;
}

@media (max-width: 769px) {
  .ticket-container-buy__price {
    border: unset;
    border-bottom: 1px solid #ab9569;
    padding: 0 0 20px 0;
  }
}

.ticket-container-buy__price .price-text {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  width: 115px;
  text-align: right;
}

.ticket-container-buy__price h4 {
  font-size: 6em;
  font-family: ratiomodern, serif;
  color: #ab9569;
}

.ticket-container-buy__price h4 span {
  transform: rotate(-90deg);
  display: block;
  font-size: 20px;
  position: absolute;
  right: 80px;
  top: 80px;
}

@media (max-width: 769px) {
  .ticket-container-buy__price h4 span {
    right: unset;
    left: 87px;
    top: 45px;
  }
}

.ticket-container-buy__form {
  padding: 50px 0 0 95px;
}

@media (max-width: 769px) {
  .ticket-container-buy__form {
    padding: 25px 0 0 0;
  }
}

.ticket-container-thanks {
  padding: 25px 0 30px 0;
  width: 610px;
  margin: 0 auto;
}

@media (max-width: 769px) {
  .ticket-container-thanks {
    width: 100%;
  }
}

.ticket-container-thanks h3 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1;
  text-transform: uppercase;
}

.ticket-container-thanks span {
  display: block;
  margin-top: 25px;
  font-size: 2em;
  font-family: ratiomodern, serif;
  font-style: italic;
}

.ticket-container-scena {
  padding: 0 160px;
}

@media (max-width: 1600px) {
  .ticket-container-scena {
    padding: 0 80px;
  }
}

@media (max-width: 1200px) {
  .ticket-container-scena {
    padding: 0 40px;
  }
}

@media (max-width: 769px) {
  .ticket-container-scena {
    padding: 0;
  }
}

.ticket-container-scena__header {
  width: 515px;
  margin: 0 auto 165px auto;
}

@media (max-width: 1600px) {
  .ticket-container-scena__header {
    margin: 0 auto 115px auto;
  }
}

@media (max-width: 1200px) {
  .ticket-container-scena__header {
    margin: 0 auto 55px auto;
  }
}

@media (max-width: 769px) {
  .ticket-container-scena__header {
    margin: 0 auto 35px auto;
  }
}

.ticket-container-scena__header p {
  font-weight: 600;
  font-size: 16px;
}

.ticket-container-scena__header span {
  font-size: 14px;
}

.ticket-container-scena__content {
  display: flex;
}

@media (max-width: 1900px) {
  .ticket-container-scena__content {
    flex-direction: column;
  }
}

.ticket-container-scena__content .ticket-container-buy__price {
  padding: 35px 0 115px 80px;
  border: unset;
  border-left: 1px solid #ab9569;
  height: max-content;
  width: max-content;
}

@media (max-width: 1900px) {
  .ticket-container-scena__content .ticket-container-buy__price {
    padding: 25px 0 30px 0;
    border: unset;
    border-bottom: 1px solid #ab9569;
  }
}

.ticket-container-scena__content .ticket-container-buy__price .price-text {
  width: 135px;
}

.ticket-container-scena__content .ticket-container-buy__price h4 span {
  right: unset;
  left: 185px;
}

@media (max-width: 1900px) {
  .ticket-container-scena__content .ticket-container-buy__price h4 span {
    left: 105px;
    top: 70px;
  }
}

.ticket-container-scena__content .ticket-container-buy__price p {
  margin-top: 30px;
  font-size: 18px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  text-align: end;
}

.ticket-container-scena__content .ticket-container-buy__price p span {
  font-weight: bold;
  font-size: 12px;
}

.ticket-container-scena__content > div:last-child {
  width: 216px;
}

.ticket-container-scena__main {
  width: 100%;
}

@media (max-width: 1100px) {
  .ticket-container-scena__main {
    overflow-x: scroll;
  }
  .ticket-container-scena__main .sectors {
    width: 950px;
  }
}

.ticket-container-scena__main .row {
  display: flex;
  margin-bottom: 8px;
}

.ticket-container-scena__main .row.end {
  justify-content: flex-end;
}

.ticket-container-scena__main .row.reverse {
  flex-direction: row-reverse;
}

.sectors {
  display: flex;
  position: relative;
}

.sectors h3 {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sectors #sectorA {
  margin-left: 70px;
  position: relative;
  left: 220px;
  bottom: 60px;
}

.sectors #sectorC {
  position: absolute;
  left: 695px;
  bottom: -200px;
}

.sectors #sectorC h3 {
  position: relative;
  left: 210px;
  top: 30px;
}

.sectors #sectorD {
  position: absolute;
  bottom: -205px;
  left: 50px;
}

.transform {
  transform: rotate(-32deg);
  margin-top: 80px;
  position: relative;
  left: -30px;
}

.scena {
  width: 355px;
  height: 195px;
  border: 1px solid #22101d;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 290px;
}

.scena span {
  font-size: 16px;
  font-weight: bold;
}

.place-item {
  margin-right: 7px;
}

.place input {
  display: none;
}

.place input:checked + span {
  border: 1px solid #ab9569;
  background-color: #ab9569;
  color: #fff;
}

.place input:checked + span.vip {
  color: #8b143b;
}

.place span {
  width: 25px;
  height: 25px;
  border: 1px solid #22101d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
}

.place span.vip {
  border-color: #b70f45;
  color: #8b143b;
}

.place.disabled {
  pointer-events: none;
}

.place.disabled span {
  opacity: 0.5;
}

.legend {
  margin: 65px auto 155px auto;
  width: 450px;
}

@media (max-width: 1900px) {
  .legend {
    margin-bottom: 15px;
  }
}

.legend h4 {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.legend > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.legend__item {
  display: flex;
  align-items: flex-end;
  width: 200px;
  font-size: 14px;
}

.legend__item span {
  display: block;
  margin-right: 7px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: 1px solid #22101d;
}

.legend__item.select span {
  background-color: #ab9569;
  border-color: #ab9569;
}

.legend__item.vip span {
  border-color: #b70f45;
}

.legend__item.reserved span {
  opacity: 0.5;
}

.select-payment {
  margin: 45px 0 180px 0;
  padding: 0 160px;
}

@media (max-width: 1600px) {
  .select-payment {
    padding: 0 80px;
    margin: 35px 0 130px 0;
  }
}

@media (max-width: 1200px) {
  .select-payment {
    padding: 0 40px;
    margin: 35px 0 90px 0;
  }
}

@media (max-width: 769px) {
  .select-payment {
    padding: 0;
    margin: 25px 0 50px 0;
  }
}

.select-payment label {
  cursor: pointer;
}

.select-payment__item {
  margin-bottom: 10px;
}

.select-payment span {
  font-size: 16px;
  font-weight: 500;
  position: relative;
  padding-left: 28px;
  display: block;
}

.select-payment span:before {
  content: '';
  position: absolute;
  left: 0px;
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid #22101d;
  border-radius: 20px;
}

.select-payment input {
  display: none;
}

.select-payment input:checked + span:before {
  background-color: #ab9569;
  border-color: #ab9569;
}

#sectorB {
  position: relative;
  left: 260px;
  width: 320px;
  height: 300px;
}

#sectorB .place-item {
  position: absolute;
}

#sectorB .row1 > div:nth-child(1) {
  bottom: 288px;
  left: 105px;
}

#sectorB .row1 > div:nth-child(2) {
  bottom: 277px;
  left: 142px;
}

#sectorB .row1 > div:nth-child(3) {
  bottom: 261px;
  left: 176px;
}

#sectorB .row1 > div:nth-child(4) {
  bottom: 237px;
  left: 211px;
}

#sectorB .row1 > div:nth-child(5) {
  bottom: 200px;
  left: 245px;
}

#sectorB .row1 > div:nth-child(6) {
  bottom: 159px;
  left: 267px;
}

#sectorB .row1 > div:nth-child(7) {
  bottom: 113px;
  left: 289px;
}

#sectorB .row2 > div:nth-child(1) {
  bottom: 261px;
  left: 82px;
}

#sectorB .row2 > div:nth-child(2) {
  bottom: 257px;
  left: 117px;
}

#sectorB .row2 > div:nth-child(3) {
  bottom: 242px;
  left: 149px;
}

#sectorB .row2 > div:nth-child(4) {
  bottom: 221px;
  left: 183px;
}

#sectorB .row2 > div:nth-child(5) {
  bottom: 186px;
  left: 215px;
}

#sectorB .row2 > div:nth-child(6) {
  bottom: 144px;
  left: 237px;
}

#sectorB .row2 > div:nth-child(7) {
  bottom: 103px;
  left: 258px;
}

#sectorB .row3 > div:nth-child(1) {
  bottom: 236px;
  left: 56px;
}

#sectorB .row3 > div:nth-child(2) {
  bottom: 229px;
  left: 90px;
}

#sectorB .row3 > div:nth-child(3) {
  bottom: 217px;
  left: 124px;
}

#sectorB .row3 > div:nth-child(4) {
  bottom: 198px;
  left: 157px;
}

#sectorB .row3 > div:nth-child(5) {
  bottom: 167px;
  left: 183px;
}

#sectorB .row3 > div:nth-child(6) {
  bottom: 129px;
  left: 207px;
}

#sectorB .row3 > div:nth-child(7) {
  bottom: 93px;
  left: 227px;
}

#sectorB .row3 > div:nth-child(8) {
  bottom: 58px;
  left: 241px;
}

#sectorB .row4 > div:nth-child(1) {
  bottom: 205px;
  left: 36px;
}

#sectorB .row4 > div:nth-child(2) {
  bottom: 203px;
  left: 66px;
}

#sectorB .row4 > div:nth-child(3) {
  bottom: 192px;
  left: 98px;
}

#sectorB .row4 > div:nth-child(4) {
  bottom: 174px;
  left: 129px;
}

#sectorB .row4 > div:nth-child(5) {
  bottom: 147px;
  left: 153px;
}

#sectorB .row4 > div:nth-child(6) {
  bottom: 114px;
  left: 174px;
}

#sectorB .row4 > div:nth-child(7) {
  bottom: 83px;
  left: 193px;
}

#sectorB .row4 > div:nth-child(8) {
  bottom: 45px;
  left: 207px;
}

#sectorB .row4 > div:nth-child(9) {
  bottom: 8px;
  left: 213px;
}

#sectorB .row5 > div:nth-child(1) {
  bottom: 173px;
  left: 16px;
}

#sectorB .row5 > div:nth-child(2) {
  bottom: 169px;
  left: 46px;
}

#sectorB .row5 > div:nth-child(3) {
  bottom: 159px;
  left: 76px;
}

#sectorB .row5 > div:nth-child(4) {
  bottom: 144px;
  left: 104px;
}

#sectorB .row5 > div:nth-child(5) {
  bottom: 123px;
  left: 126px;
}

#sectorB .row5 > div:nth-child(6) {
  bottom: 96px;
  left: 144px;
}

#sectorB .row5 > div:nth-child(7) {
  bottom: 68px;
  left: 162px;
}

#sectorB .row5 > div:nth-child(8) {
  bottom: 37px;
  left: 173px;
}

#sectorB .row5 > div:nth-child(9) {
  bottom: 3px;
  left: 179px;
}

#sectorB .row6 > div:nth-child(1) {
  bottom: 143px;
  left: 0;
}

#sectorB .row6 > div:nth-child(2) {
  bottom: 140px;
  left: 28px;
}

#sectorB .row6 > div:nth-child(3) {
  bottom: 129px;
  left: 53px;
}

#sectorB .row6 > div:nth-child(4) {
  bottom: 118px;
  left: 78px;
}

#sectorB .row6 > div:nth-child(5) {
  bottom: 98px;
  left: 98px;
}

#sectorB .row6 > div:nth-child(6) {
  bottom: 77px;
  left: 117px;
}

#sectorB .row6 > div:nth-child(7) {
  bottom: 54px;
  left: 131px;
}

#sectorB .row6 > div:nth-child(8) {
  bottom: 27px;
  left: 140px;
}

#sectorB .row6 > div:nth-child(9) {
  bottom: 0;
  left: 146px;
}

#sectorB .row7 > div:nth-child(1) {
  bottom: 110px;
  left: 0;
}

#sectorB .row7 > div:nth-child(2) {
  bottom: 106px;
  left: 27px;
}

#sectorB .row7 > div:nth-child(3) {
  bottom: 93px;
  left: 51px;
}

#sectorB .row7 > div:nth-child(4) {
  bottom: 74px;
  left: 71px;
}

#sectorB .row7 > div:nth-child(5) {
  bottom: 52px;
  left: 90px;
}

#sectorB .row7 > div:nth-child(6) {
  bottom: 27px;
  left: 105px;
}

#sectorB .row7 > div:nth-child(7) {
  bottom: 0px;
  left: 114px;
}

#sectorB .row8 > div:nth-child(1) {
  bottom: 75px;
  left: 0;
}

#sectorB .row8 > div:nth-child(2) {
  bottom: 68px;
  left: 27px;
}

#sectorB .row8 > div:nth-child(3) {
  bottom: 49px;
  left: 50px;
}

#sectorB .row8 > div:nth-child(4) {
  bottom: 28px;
  left: 69px;
}

#sectorB .row8 > div:nth-child(5) {
  bottom: 0px;
  left: 80px;
}

#sectorB .row9 > div:nth-child(1) {
  bottom: 40px;
  left: 0;
}

#sectorB .row9 > div:nth-child(2) {
  bottom: 25px;
  left: 28px;
}

#sectorB .row9 > div:nth-child(3) {
  bottom: 0px;
  left: 47px;
}
