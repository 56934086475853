.contact-container__info {
    margin-top: 80px;
    display: flex;
    align-items: flex-end;
    @media(max-width: $laptop){
        margin-top: 60px;
    }
    @media(max-width: $tablet){
        margin-top: 40px;
    }
    @media(max-width: $mobile){
        margin-top: 20px;
        flex-direction: column;
        align-items: flex-start;
    }
    >div {
        &:first-child {
            margin-right: 230px;
            @media(max-width: $laptop){
                margin-right: 150px;
            }
            @media(max-width: $tablet){
                margin-right: 60px;
            }
            @media(max-width: $mobile){
                margin-right: 0;
                margin-bottom: 30px;
            }
        }

        &:last-child {
            margin-left: 30px;
            @media(max-width: $mobile){
                margin-left: 0;
                margin-top: 30px;
            }
        }
        
        h3 {
            color: $gold;
            font-size: 2.4em;
            font-weight: bold;
        }
        
        >div {
            font-size: 16px;
            display: flex;
            margin-top: 15px;

            span {
                display: block;
                width: 55px;
                margin-right: 15px;
            }
        }

        a {
            transition: .3s ease-in-out;
            &:hover {
                color: $gold;
            }
        }
    }

    &--title {
        font-size: 2em;
        font-weight: 600;
    }
}