.header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 43px;
    background-color: $purple;

    @media(max-width: $mobile) {
        flex-direction: column;
        padding: 15px 35px;
        margin-bottom: 20px;
    }

    &__right-wrapper {
        display: flex;
        align-items: center;
        @media(max-width: $mobile) {
            flex-direction: column;
            margin-top: 5px;
        }
    }
}