#home-slider {
    position: relative;
    text-align: right;
    margin-bottom: 225px;
    @media(max-width: $laptop) {
        margin-bottom: 135px;
    }
    @media(max-width: $tablet) {
        margin-bottom: 85px;
    }
    @media(max-width: $mobile) {
        margin-bottom: 25px;
        text-align: left;
    }

    h2 {
        margin-bottom: 60px;
    }

    p {
        font-size: 2em;
        font-weight: bold;
        margin-bottom: 25px;
        @media(max-width: $laptop) {
            font-size: 1.8em;
        }
        @media(max-width: $tablet) {
            font-size: 1.6em;
        }
        @media(max-width: $mobile) {
            font-size: 1.4em;
        }
    }

    .button {
        margin-left: auto;
        @media(max-width: $mobile) {
          margin-left: 0;
        }
    }

    .line-swiper {
        top: 50%;
        width: calc(100% - 360px);
        transform: translate(160px, -50%);
    }
}

#home-slider-container {
    width: 100%;
    height: 810px;
    @media(max-width: $laptop) {
        height: 600px;
    }
    @media(max-width: $tablet) {
        height: 450px;
    }
    .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-position: center;
        @media(max-width: $mobile) {
            align-items: flex-start;
            justify-content: flex-start;
            padding-top: 25px;
        }
        &__content {
            margin-right: 160px;
            position: relative;
            top: -17px;
            @media(max-width: $laptop) {
                margin-right: 120px;
            }
            @media(max-width: $tablet) {
                margin-right: 80px;
            }
            @media(max-width: $mobile) {
                margin-right: 0;
                margin-left: 15px;
            }
        }
    }
}
