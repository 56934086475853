.events-list-previous {
    padding: 0 160px;
    margin-top: 200px;
    @media(max-width: $laptop) {
        padding: 0 80px;
        margin-top: 100px;
    }
    @media(max-width: $tablet) {
        padding: 0 40px;
        margin-top: 50px;
    }
    @media(max-width: $mobile) {
        padding: 0;
        margin-top: 30px;
    }
    h2 {
        font-size: 6em;
        font-family: $r;
        text-transform: uppercase;
        padding-left: 160px;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 75px;
        @media(max-width: $laptop) {
            padding-left: 80px;
            margin-bottom: 45px;
        }
        @media(max-width: $tablet) {
            padding-left: 40px;
            margin-bottom: 25px;
        }
        @media(max-width: $mobile) {
            padding-left: 0;
        }
    }

    &__list {
        &--item {
            display: flex;
            padding: 15px 30px;
            background: #f3f3f3;
            margin-bottom: 30px;
            @media(max-width: $laptop) {
                margin-bottom: 20px;
            }
            @media(max-width: $mobile) {
                padding: 10px 20px;
            }

            .date {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-right: 1px solid #8a8188;
                padding-right: 30px;
                font-family: $r;
                color: #a19ea0;
                font-size: 16px;
                @media(max-width: $mobile) {
                    padding-right: 20px;
                }
                
                &__top {
                    font-size: 60px;
                }
            }

            .info {
                padding-left: 45px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #60545d;
                @media(max-width: $mobile) {
                    padding-left: 15px;
                }

                h3 {
                    font-weight: bold;
                    font-size: 3em;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 1.6em;
                    font-style: italic;
                }
            }
        }
    }
}