.home-text-under-swiper {
    margin: 70px 0 160px 160px;
    line-height: 1;
    @media(max-width: $laptop) {
        margin: 50px 0 120px 120px;
    }

    @media(max-width: $tablet) {
        margin: 30px 0 90px 90px;
    }

    @media(max-width: $mobile) {
        margin: 40px 0 60px 0;
    }

    h2 {
        font-size: 4em;
        font-weight: bold;
        margin-bottom: 25px;
    }

    p {
        font-size: 2em;
        font-style: italic;
        font-family: $r;
    }
}