.select-faq-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1px 30px;
    grid-template-areas: ". .";
    height: auto;
    margin: 0 160px 30px 160px;
    @media(max-width: $laptop) {
        margin: 0 80px 30px 80px;
    }
    @media(max-width: $tablet) {
        margin: 0 40px 20px 40px;
    }
    @media(max-width: $mobile) {
        margin: 0 0 20px 0;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    > div {
        padding: 30px;
        background-color: #f3f3f3;
        transition: .3s ease-in-out;

        &:first-child {
            @media(max-width: $mobile) {
                margin-bottom: 30px;
            }
        }

        &:hover {
            color: #fff;
            background-color: $gold;
            a {
                border-color: #fff;
                color: #fff;
            }
        }

        img {
            max-height: 220px;
            width: 100%;
        }

        h4 {
            margin: 35px 0px;
            font-size: 30px;
            font-weight: bold;
        }

        p {
            font-style: italic;
            font-size: 16px; 
            font-family: $r;
        }

        a {
            margin-top: 45px;
            color: $gold;
        }
    }
}