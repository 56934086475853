.wrapper {
    padding: 0 160px;
    @media(max-width: $laptop) {
        padding: 0 80px;
    }
    @media(max-width: $tablet) {
        padding: 0 40px;
    }
    @media(max-width: $mobile) {
        padding: 0 15px;
    }
}