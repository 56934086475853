.single-event-container {
    display: flex;
    padding-top: 115px;
    @media(max-width: $laptop) {
        padding-top: 85px;
    }
    @media(max-width: $tablet) {
        padding-top: 45px;
    }
    @media(max-width: $mobile) {
        padding-top: 25px;
        flex-direction: column-reverse;
    }
}