#space-between-comments {
    height: 90px;
    width: 1px;
    background-color: $gold;
    margin: 110px auto 85px auto;
    @media(max-width: $laptop){
        margin: 90px auto 65px auto;
    }
    @media(max-width: $tablet){
        margin: 70px auto 45px auto;
    }
    @media(max-width: $mobile){
        margin: 50px auto 25px auto;
    }
}