.form-meet {
    margin: 105px 320px 135px 320px;
    @media(max-width: $laptop){
        margin: 85px 240px 95px 240px;
    }
    @media(max-width: $tablet){
        margin: 55px 160px 75px 160px;
    }
    @media(max-width: $mobile){
        margin: 15px 0;
    }
    h3 {
        font-size: 60px;
        font-family: $r;
        font-weight: 500;
        margin-bottom: 10px;
        text-transform: uppercase;
        @media(max-width: $mobile){
            font-size: 48px;
        }
    }

    p {
        font-size: 20px;
        @media(max-width: $mobile){
            font-size: 16px;
        }
    }

    .row {
        display: grid;
        grid-template-columns: 1.5fr 0.5fr 1fr;
        grid-template-rows: 1fr;
        gap: 1px 30px;
        grid-template-areas: ". . .";
        margin: 60px 0 0 0;
        @media(max-width: $mobile){
            margin: 10px 0 0 0;
            display: flex;
            flex-direction: column;

            label {
                margin-top: 25px;
            }
        }

        &.row-4 {
            grid-template-columns: 1.8fr 0.5fr 0.5fr 0.5fr;
            grid-template-areas: ". . . .";
        }
        &.row-2 {
            grid-template-columns: 1fr 1fr;
            margin-top: 25px;
            grid-template-areas: ". .";
        }
    }

    .flexLabel {
        display: flex;
        flex-direction: column;
        margin-top: 25px;

        input {
            width: 100%;
        }
    }

    
    label {
        font-weight: bold;
        font-size: 12px;
    }

    input {
        width: 100%;
        padding-left: 15px;
        font-size: 14px;
        height: 30px;
        border: unset;
        border-bottom: 1px solid #767074;
    }

    br {
        display: none;
    }

    input[type=submit] {
        width: 290px;
        height: 47px;
        margin-top: 35px;
        border: unset;
        border: 1px solid $gold;
        border-radius: 50px;
        transition: .3s ease-in-out;
        background: #fff;
        padding: 0;

        &:hover {
            color: #fff;
            cursor: pointer;
            background: $gold;
        }
    }

    // input[type=checkbox] {
    //    position: absolute;
    //    opacity: 0;
    // }
}

#accept-rules {
    margin-top: 20px;
    cursor: pointer;
    label {
        display: flex;
        font-weight: normal;
    }
}

.screen-reader-response, .wpcf7-validation-errors, .wpcf7-not-valid-tip {
    margin-top: 10px;
    font-size: 12px;
    color: red;
}

// checkbox
.styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    
    & + span {
        position: relative;
        cursor: pointer;
        padding: 0;
        padding-left: 25px;
    }
  
    // Box.
    & + span:before {
      content: '';
      position: absolute;
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 15px;
      height: 15px;
      background: white;
      border: 1px solid $black;
      border-radius: 3px;
      transition: .2s ease;
      left: 0;
    }
  
    // Box hover
    &:hover + span:before {
      background: $gold;
    }
    
    // Box focus
    &:focus + span:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      border: 1px solid $gold;
    }
  
    // Box checked
    &:checked + span:before {
      background: $gold;
      border: 1px solid $gold;
    }
    
    // Disabled state span.
    &:disabled + span {
      color: #b8b8b8;
      cursor: auto;
    }
  
    // Disabled box.
    &:disabled + span:before {
      box-shadow: none;
      background: #ddd;
    }
  
    // Checkmark. Could be replaced with an image
    &:checked + span:after {
      content: '';
      position: absolute;
      left: 3px;
      top: 7px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }