$gold: #ab9569;
$purple: #651932;
$white: #fff;
$black: #22101d;
$grey: #a9a5a8;

$p: proxima-nova, sans-serif;
$r: ratiomodern, serif;

$laptop: 1600px;
$tablet: 1200px;
$mobile: 769px;

$mainShadow: 0px 8px 35px -16px rgba(0,0,0,0.75); // too change