.footer-container {
    &__top {
        >span {
            display: block;
        }

        .title-footer {
            font-weight: bold;
            margin-bottom: 20px;
            @media(max-width: $mobile){
                margin-bottom: 10px;
            }
        }
    }
}