.main-nav__wrapper {
    font-size: 1.6em;
    font-weight: bold;
    color: $purple;
    display: flex;

    @media(max-width: $mobile){
        flex-direction: column;
        margin-top: 20px;
    }
}