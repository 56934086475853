.offer-container {
    padding: 115px 160px 0 160px;
    display: flex;
    justify-content: space-between;

    @media(max-width: $laptop){
        padding: 95px 80px 0 80px;
    }
    @media(max-width: $tablet){
        padding: 75px 40px 0 40px;
    }
    @media(max-width: $mobile){
        padding: 45px 0 0 0;
        flex-direction: column;
    }
}