.to-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.margin-to-center {
    margin: 0 auto;
}

.disabled {
    pointer-events: none;
    opacity: 0.2;
}