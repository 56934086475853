#hamburger {
    width: 18px;
    height: 18px;
    position: fixed;
    top: 15px;
    right: 15px;
    display: none;
    z-index: 9999;
    @media(max-width: $mobile) {
        display: block;
    }
    >div {
        display: block;
        width: 100%;
        height: 2px;
        background: $gold;
        position: absolute;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .3s ease-in-out;
    }
}

#hamburger div:nth-child(1) {
    top: 0px;
}

#hamburger div:nth-child(2) {
    top: 6px;
}

#hamburger div:nth-child(3) {
    top: 12px;
}

#hamburger.open div:nth-child(1) {
    top: 6px;
    transform: rotate(135deg);
}

#hamburger.open div:nth-child(2) {
    opacity: 0;
    left: -60px;
}

#hamburger.open div:nth-child(3) {
    top: 6px;
    transform: rotate(-135deg);
}