.single-event-container {
    &__content {
        padding: 0 160px 0 95px;
        @media(max-width: $laptop) {
            padding: 0 80px;
        }
        @media(max-width: $tablet) {
            padding: 0 40px;
        }
        @media(max-width: $mobile) {
            padding: 0;
        }
        h1 {
            font-size: 4em;
            font-weight: bold;
            margin-bottom: 30px;
            @media(max-width: $mobile) {
                margin-bottom: 15px;
            }
        }

        p {
            font-size: 1.6em;
            font-weight: 600;
        }
        .first-paragraph {
            font-weight: 500;
            font-size: 2em;
            font-family: $r;
            font-style: italic;
            margin-bottom: 80px;
            @media(max-width: $mobile) {
                margin-bottom: 40px;
            }
        }
        .last-paragraph {
            margin-bottom: 60px;
            @media(max-width: $mobile) {
                margin-bottom: 30px;
            }
        }
    }

    .buy-ticket {
        padding-top: 90px;
        position: relative;
        @media(max-width: $tablet) {
            padding-top: 60px;
        }
        @media(max-width: $mobile) {
            padding-top: 30px;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: -96px;
            width: 1px;
            height: 100%;
            background: #fff;
            z-index: 999;
        }
        
        h3 {
            margin-bottom: 25px;
            font-size: 3.2em;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1;
        }
    }
}