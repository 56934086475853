.events-list {
    .line {
        margin: 80px 0 0 auto;
        width: calc(100% - 480px);
        @media(max-width: $laptop) {
            margin: 60px 0 0 auto;
            width: calc(100% - 280px);
        }
        @media(max-width: $tablet) {
            margin: 40px 0 0 auto;
            width: calc(100% - 140px);
        }
        @media(max-width: $mobile) {
            margin: 20px 0 0 auto;
        }
    }

    &__item {
        margin-bottom: 80px;
        @media(max-width: $laptop) {
            margin-bottom: 60px;
        }
        @media(max-width: $tablet) {
            margin-bottom: 40px;
        }
        @media(max-width: $mobile) {
            margin-bottom: 20px;
        }
        &:last-child {
            margin-bottom: 0;
        }

        p {
            font-style: italic;
            font-family: $r;
        }

        .line-item {
            width: 450px;
            height: 1px;
            background: $gold;
            position: relative;
            top: -20px;
            right: 320px;
            @media(max-width: $tablet) {
                display: none;
            }
        }
    }
}

.events-empty {
    font-size: 2em;
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
}