.ticket-container-scena {
    padding: 0 160px;
    @media(max-width: $laptop) {
        padding: 0 80px;
    }  
    @media(max-width: $tablet) {
        padding: 0 40px;
    }
    @media(max-width: $mobile) {
        padding: 0;
    }

    &__header {
        width: 515px;
        margin: 0 auto 165px auto;
        @media(max-width: $laptop) {
            margin: 0 auto 115px auto;
        }  
        @media(max-width: $tablet) {
            margin: 0 auto 55px auto;
        }
        @media(max-width: $mobile) {
            margin: 0 auto 35px auto;
        }
        p {
            font-weight: 600;
            font-size: 16px;
        }
        span {
            font-size: 14px;
        }
    }

    &__content {
        display: flex;
        @media(max-width: 1900px) {
            flex-direction: column;
        }  
        .ticket-container-buy__price {
            padding: 35px 0 115px 80px;
            border: unset;
            border-left: 1px solid $gold;
            height: max-content;
            width: max-content;
            @media(max-width: 1900px) {
                padding: 25px 0 30px 0;
                border: unset;
                border-bottom: 1px solid $gold;
             }  
            .price-text{
                width: 135px;
            }

            h4 {
                // width: 130px;
                span {
                    right: unset;
                    left: 185px;
                    @media(max-width: 1900px) {
                      left: 105px;
                      top: 70px;
                    }  
                }
            }

            p {
                margin-top: 30px;
                font-size: 18px;
                text-transform: uppercase;
                display: flex;
                flex-direction: column;
                text-align: end;
                span {
                    font-weight: bold;
                    font-size: 12px;
                }
            }
        }

        >div {
            &:last-child {
                width: 216px;
            }
        }
    }

    &__main {
        width: 100%;
        @media(max-width: 1100px) {
            // width:950px;
            overflow-x: scroll;

            .sectors {
                width:950px;
            }
        }
        .row {
            display: flex;
            margin-bottom: 8px;

            &.end {
                justify-content: flex-end;
            }

            &.reverse {
                flex-direction: row-reverse;
            }
        }
    }
}

.sectors {
    display: flex;
    position: relative;
    
    h3 {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
        // text-align: center;
    }

    #sectorA {
        margin-left: 70px;
        position: relative;
        left: 220px;
        bottom: 60px;
    }

    #sectorC {
        // display: none;
        position: absolute;
        left: 695px;
        bottom: -200px;

        h3 {
            position: relative;
            left: 210px;
            top: 30px;
        }
    }

    #sectorD {
        // display: none;
        position: absolute;
        bottom: -205px;
        left: 50px;
    }
}

.transform {
    transform: rotate(-32deg);
    margin-top: 80px;
    position: relative;
    left: -30px;
}

.scena {
    width: 355px;
    height: 195px;
    border: 1px solid $black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 290px;
    span {
        font-size: 16px;
        font-weight: bold;
    }
}