#footer-event {
    padding: 30px;
    background: #f3f3f3;
    display: flex;
    margin: 0 160px;
    @media(max-width: $laptop){
        margin: 0 80px;
     }
    @media(max-width: $tablet){
        margin: 0 40px;
     }
     @media(max-width: $mobile){
        margin: 0;
        flex-direction: column;
        padding: 15px;
     }
}

.last-element {
   margin-bottom: 185px;
   @media(max-width: $laptop){
      margin-bottom: 145px;
   }
  @media(max-width: $tablet){
      margin-bottom: 105px;
   }
   @media(max-width: $mobile){
      margin-bottom: 55px;
   }
}