.faq-container {
    nav {
        width: 250px;
        .title {
            font-weight: 600;
            font-size: 1.4em;
            display: block;
            margin-bottom: 5px;
        }

        ul {
            margin-bottom: 26px;
        }

        li {
            span {
                font-size: 1.8em;
                width: fit-content;
                // margin: 0 25px 5px 0;
                margin-bottom: 5px;
                cursor: pointer;
                background: #fff;
                padding-right: 25px;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    opacity: 0;
                    bottom: 0;
                    left: 0;
                    width: calc(100% - 25px);
                    height: 1px;
                    background: $gold;
                }

                &.is-active {
                    color: $gold;
                    &:after {
                        opacity: 1;
                    }
                    // border-bottom: 1px solid $gold;
                }
            }
            
        }
    }
}