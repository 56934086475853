.main-nav {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: $tablet){
        height: 100px;
    }
    @media(max-width: $mobile){
        width: 100%;
        height: 100%;
        background-color: #fff;
        flex-direction: column;
        justify-content: flex-start;
        position: fixed;
        right: -2000px;
        top: 0;
        transition: .3s ease-in-out;
    }

    &.open {
        right: 0;
        z-index: 9999999;
    }
}