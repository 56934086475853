.swiper-main-container {
    position: relative;
    h2 {
        font-size: 6em;
        font-family: $r;
        font-weight: 400;
        @media(max-width: $laptop) {
            font-size: 5em;
        }
        @media(max-width: $tablet) {
            font-size: 4em;
            margin-bottom: 40px;
        }
        @media(max-width: $mobile) {
            font-size: 3em;
            margin-bottom: 20px;
        }
    }

}

.line-swiper {
    position: absolute;
    right: 0;
    // left: 50%;
    z-index: 9  ;

    transform: translate(160px, -50%);
    @media(max-width: $mobile) {
        display: none;
    }
}



// bullets
.swiper-pagination-bullets {
    display: flex;
    flex-direction: column;
    align-items: center;

    left: -40px;
    top: 50%;
    transform: translate3d(0px,-50%,0);
    @media(max-width: $tablet) {
        left: -20px;
    }
    @media(max-width: $mobile) {
        transform: translate(-50%, 50%);
        top: 0;
        left: 50%;
        bottom: -10px;
        flex-direction: row;
    }
}

.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    opacity: 1;
    background: #fff;
    border: 1px solid $gold;
    margin: 11px 0;
    outline: none;

    @media(max-width: $mobile) {
        margin: 11px 11px 0 11px;
    }
}

.swiper-pagination-bullet-active {
    width: 6px;
    height: 6px;
    background: $gold;
}

.swiper-container-photo {
    max-width: 610px;
    max-height: 435px;
    overflow: hidden;
    z-index: -1;
}