.contact-container {
    padding: 115px 0 0 160px;
    margin-bottom: 170px;

    @media(max-width: $laptop){
        padding: 95px 0 0 80px;
        margin-bottom: 120px;
    }
    @media(max-width: $tablet){
        padding: 75px 0 0 40px;
        margin-bottom: 70px;
    }
    @media(max-width: $mobile){
        padding: 45px 0 0 0;
        margin-bottom: 20px;
    }
}