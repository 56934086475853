.events-header {
    margin: 115px 0 150px 160px;
    @media(max-width: $laptop) {
        margin: 85px 0 110px 80px;
    }
    @media(max-width: $tablet) {
        margin: 45px 0 70px 40px;
    }
    @media(max-width: $mobile) {
        margin: 15px 0 30px 0;
    }

    h1 {
        font-size: 6em;
        font-family: $r;
        text-transform: uppercase;
        padding-left: 160px;
        font-weight: 400;
        line-height: 1;
        @media(max-width: $laptop) {
            padding-left: 80px;
        }
        @media(max-width: $tablet) {
            padding-left: 40px;
        }
        @media(max-width: $mobile) {
            padding-left: 0;
        }
    }

    h2 {
        font-size: 4em;
        padding-left: 160px;
        font-weight: bold;
        line-height: 1;
        margin-top: 15px;
        @media(max-width: $laptop) {
            padding-left: 80px;
        }
        @media(max-width: $tablet) {
            padding-left: 40px;
        }
        @media(max-width: $mobile) {
            padding-left: 0;
        }
    }

    .line {
        margin: 40px 0;
        @media(max-width: $laptop) {
            margin: 30px;
        }
        @media(max-width: $tablet) {
            margin: 20px;
        }
        @media(max-width: $mobile) {
            margin: 10px 0;
        }
    }

    p {
        font-size: 2em;
        padding-left: 160px;
        @media(max-width: $laptop) {
            padding-left: 80px;
        }
        @media(max-width: $tablet) {
            padding-left: 40px;
        }
        @media(max-width: $mobile) {
            padding-left: 0;
        }
    }
}