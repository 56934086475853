#comments {
    margin: 85px auto 0 auto;
    min-height: 140px;
    width: 930px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    @media(max-width: $tablet){
        width: 100%;
    }
    img {
        width: 66px;
        height: 44px;
        position: absolute;
        left: 0;
        top: 0;
        @media(max-width: $tablet){
            top: -50px;
        }
    }

    p {
        font-size: 2.4em;
        font-family: $r;
        font-style: italic;
    }

    span {
        font-size: 1.6em;
        display: block;
        margin-top: 30px;
        font-weight: bold;
    }
}