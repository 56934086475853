.rooms-photos-container {
    padding: 0 160px;
    margin-bottom: 110px;;
    @media(max-width: $laptop){
        padding: 0 80px;
        margin-bottom: 60px;;
    }
    @media(max-width: $tablet){
        padding: 0 40px;
        margin-bottom: 40px;;
    }
    @media(max-width: $mobile){
        padding: 0;
        margin-bottom: 10px;;
    }
    img {
        width: 100%;
        height: 100%;
        max-height: 510px;
        @media(max-width: $laptop){
            max-height: 460px;
        }
        @media(max-width: $tablet){
            max-height: 410px;
        }
        @media(max-width: $mobile){
            margin-bottom: 30px;
            max-height: 300px;
        }
    }
    >div {
        height: 510px;
        margin-bottom: 30px;
        @media(max-width: $laptop){
            height: 460px;
        }
        @media(max-width: $tablet){
            height: 410px;
        }
        @media(max-width: $mobile){
            // height: 350px;

            height: auto;
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
        }
    }
}

.row-1{
    display: grid;
    grid-template-columns: 1.4fr 0.6fr;
    grid-template-rows: 1fr;
    gap: 0px 30px;
    grid-template-areas: ". .";
}

.row-2 {
    display: grid;
    grid-template-columns: 0.4fr 1.6fr;
    grid-template-rows: 1fr;
    gap: 0px 30px;
    grid-template-areas: ". .";
}