.menu-container {
    &__row {
        h3 {
            font-size: 3em;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 40px;
        }
        h5 {
            font-size: 12px;
            color: $black;
            font-weight: 600;
            text-transform: uppercase;
        }

        >div {
            display: flex;
            // justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 100px;
            @media(max-width: $mobile) {
                margin-bottom: 50px;
            }
        }


        &--single {
            font-size: 2em; 
            font-weight: bold;  
            color: $gold;
            width: 450px;
            margin: 0 100px 25px 0;
            // margin-bottom: 25px;
            @media(min-width: 1850px) {
                &:nth-child(3n+3){
                    margin-right: 0;
                }
            }
            @media(max-width: 1850px) {
                &:nth-child(2n+2){
                    margin-right: 0;
                }
            }

            @media(max-width: $tablet) {
                margin-right: 50px;
            }

            @media(max-width: $mobile) {
                margin-right: 0;
                width: 100%;
            }
            
            p {
                font-family: $r;
                color: $black;
                font-weight: 500;
                font-style: italic;
                letter-spacing: -1px;

                &.room-desc {
                    font-style: normal;
                    margin-top: 25px;
                    font-weight: normal;
                    font-family: $p;
                    font-size: 16px;
                }
            }

            >div {
                display: flex;
                align-items: center;
                &.wine-title {
                    flex-direction: column;
                    align-items: flex-start;
                    margin: 15px 0;
                    >div {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
                &.drink-title {
                    color: $black;
                    font-size: 18px;

                    &.only-price {
                        justify-content: flex-end;
                    }

                    span {
                        font-weight: 400;
                    }
                }
            }

            .dots {
                margin: 0 15px 0 25px;
                flex: 1;
                border-bottom: 1px dashed $gold;
            }
        }
    }

    /// NAPOJE
    &.drinks {  
        .menu-container__row {
            display: flex;
            flex-wrap: wrap;
            >div {
                margin-right: 100px;
                flex-direction: column;
                @media(min-width: 1850px) {
                    &:nth-child(3n+3){
                        margin-right: 0;
                    }
                }
                @media(max-width: 1850px) {
                    &:nth-child(2n+2){
                        margin-right: 0;
                    }
                }    
            }

            &--single {
                margin-right: 0;
            }
        }
    }
}