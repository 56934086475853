.footer-container {
    &__column-5 {
        svg {
            width: 40px;

            &:hover {
                .st0, .st1 {
                    fill: $black;
                }
            }
        }
    }
}