.legend {
    margin: 65px auto 155px auto;
    width: 450px;
    @media(max-width: 1900px) {
        margin-bottom: 15px;
    }  
    h4 {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    >div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    &__item {
        display: flex;
        align-items: flex-end;
        width: 200px;
        font-size: 14px;
        span {
            display: block;
            margin-right: 7px;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            border: 1px solid $black;
        }

        &.select {
            span {
                background-color: $gold;
                border-color: $gold;
            }
        }

        &.vip {
            span {
                border-color: #b70f45;
            }
        }

        &.reserved {
            span {
                opacity: 0.5;
            }
        }
    }
}


// payment
.select-payment {
    margin: 45px 0 180px 0;
    padding: 0 160px;
    @media(max-width: $laptop) {
        padding: 0 80px;
        margin: 35px 0 130px 0;
    }  
    @media(max-width: $tablet) {
        padding: 0 40px;
        margin: 35px 0 90px 0;
    }
    @media(max-width: $mobile) {
        padding: 0;
        margin: 25px 0 50px 0;
    }
    label {
        cursor: pointer;
    }
    &__item {
        margin-bottom: 10px;
    }
    span {
        font-size: 16px;
        font-weight: 500;
        position: relative;
        padding-left: 28px;
        display: block;
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            display: block;
            width: 18px;
            height: 18px;
            border: 1px solid $black;
            border-radius: 20px;
        }
    }
    
    input {
        display: none;
        &:checked + span{
            &:before {
                background-color: $gold;
                border-color: $gold;
            }
        }
    }
}