.ticket-container-buy {
    padding: 25px 160px 30px 160px;
    display: flex;
    
    @media(max-width: 1900px) {
        padding: 25px 80px 30px 80px;
    }  
    @media(max-width: $tablet) {
        padding: 25px 40px 30px 40px;
    }
    @media(max-width: $mobile) {
        padding: 0;
        flex-direction: column;
    }
    &__price {
        padding: 35px 95px 115px 25px;
        border-right: 1px solid $gold;
        display: flex;
        flex-direction: column;
        position: relative;
        @media(max-width: $mobile) {
            border: unset;
            border-bottom: 1px solid $gold;
            padding: 0 0 20px 0;
        }
        .price-text {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
            width: 115px;
            text-align: right;
        }

        h4 {
            font-size: 6em;
            font-family: $r;
            color: $gold;
            span {
                transform: rotate(-90deg);
                display: block;
                font-size: 20px;
                position: absolute;
                right: 80px;
                top: 80px;
                @media(max-width: $mobile) {
                    right: unset;
                    left: 87px;
                    top: 45px;;
                }
            }
        }
    }

    &__form {
        padding: 50px 0 0 95px;
        @media(max-width: $mobile) {
            padding: 25px 0 0 0;
        }
    }
}