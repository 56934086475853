.offer-container__content {
    padding-right: 60px;
    @media(max-width: $mobile){
       padding-right: 0;
    }
    h1 {
        font-size: 4em;
        margin-bottom: 35px;
        font-weight: bold;
        @media(max-width: $tablet){
           margin-bottom: 25px;
        }
        @media(max-width: $mobile){
           margin-bottom: 15px;
        }
    }

    span {
        font-family: $r;
        font-style: italic;
        font-size: 1.6em;
    }

    p {
        font-size: 1.6em;
        margin-top: 75px;
        max-width: 930px;
        @media(max-width: $tablet){
            margin-top: 45px;
        }
        @media(max-width: $mobile){
            margin-top: 15px;
        }
    }

    a {
        margin-top: 50px;
    }
}

.download-container {
    display: flex;
    margin-top: 55px;
    @media(max-width: $mobile){
        flex-direction: column;
        margin-top: 25px;
    }
    >div {
        &:first-child {
            margin-right: 30px;
            @media(max-width: $mobile){
                margin: 0 0 25px 0;
            }
        }

        span {
            font-style: normal;
            font-family: $p;
            font-weight: bold;
        }
        a {
            margin-top: 15px;
        }
    }
}