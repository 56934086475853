.rooms-container {
   margin: 140px 0 130px 0;
    @media(max-width: $laptop){
        margin: 110px 0 90px 0;
    }
    @media(max-width: $tablet){
        margin: 60px 0;
    }
    @media(max-width: $mobile){
        margin: 30px 0;
    }
   .menu-container__row--single {
       margin-bottom: 45px;

       &:last-child {
        @media(max-width: $mobile){
            margin-bottom: 5px;
        }
       }
   }
}