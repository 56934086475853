.place-item {
    margin-right: 7px;
}

.place {
    input {
        display: none;

        &:checked {
            + span {
                border: 1px solid $gold;
                background-color: $gold;
                color: #fff;

                &.vip {
                    color: #8b143b;
                }
            }
        }
    }

    span {
        width: 25px;
        height: 25px;
        border: 1px solid $black;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        font-weight: bold;
        font-size: 12px;
   
        // vip
        &.vip {
            border-color: #b70f45;
            color: #8b143b;
        }
    }

    &.disabled {
        pointer-events: none;

        span {
            opacity: 0.5;
        }
    }

}