.rooms {
    margin: 130px 0 100px 0;
    @media(max-width: $laptop){
        margin: 90px 0 70px 0;
    }
    @media(max-width: $tablet){
        margin: 50px 0 30px 0;
    }
    @media(max-width: $mobile){
        margin: 30px 0;
    }
    h3 {
        font-size: 4em;
        margin-bottom: 90px;
        font-weight: bold;
        padding-left: 160px;
        @media(max-width: $laptop){
            padding-left: 80px;
        }
        @media(max-width: $tablet){
           margin-bottom: 45px;
           padding-left: 40px;
        }
        @media(max-width: $mobile){
           margin-bottom: 15px;
           padding-left: 0;
         }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &--single {
            margin-right: 50px;
            @media(max-width: $tablet){
                margin-right: 0;
            }
            @media(max-width: $mobile){
                margin-top: 30px;
            }
            &:last-child {
                margin-right: 0;
            }
            &:first-child {
                @media(max-width: $mobile){
                    margin-top: 0;
                }
            }
            >div {
                &:first-child {
                    display: flex;
                    align-items: flex-end;
                }
                h4 {
                    font-size: 2.4em;
                    margin-top: 25px;
                    font-weight: bold;
                    color: $gold;
                    @media(max-width: $tablet){
                        margin-top: 10px;
                    }
                }

                p {
                    font-size: 1.6em;
                    margin-bottom: 5px;
                }

                img {
                    margin-top: 25px;
                    max-width: 250px;
                    max-height: 165ox;
                    width: 100%;
                    height: 100%;
                    @media(max-width: $tablet){
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.rooms-number {
    font-size: 6em;
    font-family: $r;
    color: $gold;
    display: block;
}

.rooms-line {
    width: 210px;
    height: 1px;
    background: $gold;
    display: block;
    margin: 0 0 15px 25px;
}