.offer-container__menu {
    margin-left: 90px;
    position: relative;
    width: 210px;
    flex-shrink: 0;
    @media(max-width: $laptop){
       margin-left: 60px;
    }
    @media(max-width: $tablet){
        margin-left: 40px;
    }
    @media(max-width: $mobile){
       margin: 30px 0 0 0;
       border-top: 1px solid $gold;
       padding-top: 30px;
    }
    span {
        font-size: 1.2em;
        font-weight: bold;
        text-transform: uppercase;
    }

    ul {
        margin-top: 20px;

        li {
            margin-top: 10px;

            a {
                transition: .3s ease-in-out;
                font-size: 2em;
                &:hover {
                    color: $gold;
                }

                &.is-active {
                    color: $gold;
                }
            }
        }
    }

    &:after {
        content: '';
        width: 1px;
        height: 500px;
        background: $gold;
        position: absolute;
        top: -215px;
        left: -90px;
        z-index: 99;
        @media(max-width: $laptop){
            left: -60px;
        }
        @media(max-width: $tablet){
            left: -40px;
        }
        @media(max-width: $mobile){
            display: none;
        }
    }
}