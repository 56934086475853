.faq-container {
    margin-top: 75px;
    padding: 0 160px;
    @media(max-width: $laptop){
        padding: 0 80px;
        margin-top: 55px;
    }
    @media(max-width: $tablet){
        padding: 0 40px;
        margin-top: 35px;
    }
    @media(max-width: $mobile){
        padding: 0;
        margin-top: 25px;
    }
    &__content {
        padding-top: 20px;
        margin-left: 225px;
        width: 100%;
        z-index: 1;

        @media(max-width: $laptop){
            margin-left: 185px;
        }
        @media(max-width: $tablet){
            margin-left: 145px;
        }
        @media(max-width: $mobile){
            margin-left: 0;
        }

        &.hide {
            position: absolute;
            left: -99999px;
        }

        &.is-nav-fixed {
            margin-left: 475px;
        }
    }
    &__header{
        display: block;
        margin-bottom: 20px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
    }
}