.button {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 130px;
    width: max-content;
    padding: 0 33px;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid $gold;
    border-radius: 30px;
    color: $gold;
    transition: .3s ease-in-out;

    &:hover {
        color: #fff;
        background-color: $gold;
        box-shadow: $mainShadow;
    }

    &.long {
        width: 290px;
    }

    &.reverse {
        color: #fff;
        background-color: $gold;

        &:hover {
            color: $gold;
            background-color: #fff;
        }
    }
}

.buy-ticket {
    margin-top: 214px;
    @media(max-width: 1900px) {
        margin-top: 20px;
    }  
}