#map {
    width: 100%;
    height: 515px;
    margin-bottom: 100px;
    @media(max-width: $tablet){
        margin-bottom: 60px;
    }
    @media(max-width: $tablet){
        margin-bottom: 30px;
    }
}