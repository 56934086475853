.rooms-photos-container {
    &.wedding {
        margin: 115px 0;
        @media(max-width: $laptop) {
            margin: 95px 0;
        }
        @media(max-width: $tablet) {
            margin: 65px 0;
        }
        @media(max-width: $mobile) {
            margin: 35px 0;
        }
        .row-3 {
            height: 295px;
            display: grid;
            grid-template-columns: 1.1fr 1.1fr 0.8fr;
            grid-template-rows: 1fr;
            gap: 0px 30px;
            grid-template-areas: ". . .";

            @media(max-width: $mobile) {
                display: flex;
                flex-direction: column;
                height: auto;
            }
        }
        .row-4 {
            display: grid;
            grid-template-columns: 1.1fr 1.1fr;
            grid-template-rows: 1fr;
            gap: 0px 30px;
            grid-template-areas: ". .";
            @media(max-width: $mobile) {
                // margin: 30px 0;
                display: flex;
                flex-direction: column;
                height: auto;
            }
        }

        .row-5 {
            display: grid;
            grid-template-columns: 1.4fr 0.8fr;
            grid-template-rows: 1fr;
            gap: 0px 30px;
            grid-template-areas: ". .";
            height: auto;
            @media(max-width: $mobile) {
                // margin: 30px 0;
                display: flex;
                flex-direction: column;
                height: auto;
            }
            img {
                max-height: none;
            }
            div {
                display: flex;
                flex-direction: column;
                img {
                    max-height: none;
                    &:first-child {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}