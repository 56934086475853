.nested-nav-container {
    @media(max-width: $mobile){
        flex-direction: column;
    }
}

.nested-nav-wrapper {
    position: absolute;
    width: 135px;
    left: -20px;
    top: 39px;
    padding: 20px 0 20px 20px;
    box-shadow: $mainShadow;
    opacity: 0;
    z-index: -9;
    transition: .3s;
    background-color: #fff;

    @media(max-width: $mobile){
        position: relative;
        display: none;
        opacity: 1;
        width: auto;
        box-shadow: unset;

        flex-direction: column;
        align-items: center;

        @media(max-width: $mobile){
            top: 0;
            left: 0;
            padding: 0;
        }
    }

    &.is-active {
        opacity: 1;
        z-index: 9;
        @media(max-width: $mobile){
            display: flex;
            margin-top: 15px;
        }
    }

    li {
        margin: 18px 0 0 0;
        width: fit-content;
        @media(max-width: $mobile){
            margin-top: 8px;
            // width: auto;
            &:after {
                content: '';
                position: absolute;
                top: calc(50% - 3px);
                left: -10px;
                width: 3px;
                height: 3px;
                background-color: $purple;
                border-radius: 50%;
            }
        }
        &:first-child {
            margin-top: 2px;
        }

        a {
            @media(max-width: $mobile){
                font-size: 14px;
            }
            &:after {
                bottom: -8px;
            }
        }
    }
}