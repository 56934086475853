.main-nav__wrapper {
    li {
        margin-left: 25px;
        position: relative;

        @media(max-width: $mobile){
            margin: 18px 0 0 0;
            display: flex;
            justify-content: center;
        }
        a {
            transition: .3s ease-in-out;
            
            &:after {
                content: '';
                width: 0%;
                height: 1px;
                position: absolute;
                bottom: -20px;
                left: 0;
                background-color: $gold;
                transition: .3s ease-in-out;
                left:50%;
                @media(max-width: $mobile){
                    bottom: -8px;
                }
            }
                  
            @media(min-width: $mobile){
                &:hover {    
                    color: $gold;
                    &:after {
                        animation: line .3s ease-in-out forwards;
                    }
                }
            }
            // is active
            &.is-active {
                color: $gold;
                &:after {
                    width: 100%;
                    left: 0;
                    background-color: $gold;
                }
            }

            &.nested {
                @media(max-width: $mobile){
                    text-align: center;
                    position: relative;
                }
                &:before {
                    content: '';
                    position: absolute;
                    bottom: -24px;
                    width: 0; 
                    left: calc(50% - 4px);
                    height: 0; 
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-bottom: 4px solid $gold;
                    transform: rotate(-180deg);
                    opacity: 0;
                    @media(max-width: $mobile){
                        bottom: -12px;
                    }
                }

                @media(min-width: $mobile){
                    &:hover {
                        &:before {
                            animation: triangle .3s ease-in-out forwards;
                        }
                    }
                }

                //is acitve nested
                &.is-active {
                    &:before {
                        opacity: 1;
                        bottom: -19px;
                        transform: rotate(0);
                        @media(max-width: $mobile){
                            bottom: -8px;
                        }
                    }
                }
            }
        }
    }
}

@keyframes triangle {
    from {
       opacity: 0;
    }
    to {
        opacity: 1;
    }
}
  
@keyframes line {
    from {
      left:50%;
      width:0%;
    }
    to {
      left:0%;
      width:100%;
    }
}