#freshMailPopUp__background {
    position: relative;
    #fm_form_1 {
        background-color: $gold !important;
        padding: 0 !important;
    }

    input[type=text] {
        border: unset !important;
        border-bottom: 1px solid #fff !important;
        font-size: 14px !important;
        background: $gold !important;
        width: 290px !important;
        color: #fff !important;
        
        &::placeholder { 
            color: #fff !important;
            opacity: 0.5 !important; 
          }
    }

    input[type=checkbox] {
        margin-top: 20px !important;
    }

    button {
        border: 1px solid #fff !important;
        border-radius: 30px !important;
        width: 130px !important;
        height: 37px !important;
        color: #fff !important;
        background-color: $gold !important;
        position: absolute;
        right: 0;
        top: 10px;
        cursor: pointer;

        &:hover {
            color: $gold !important;
            background-color: #fff !important;
        }
    }

    p {
        span {
            margin-top: 20px;
            color: #fff;
        }
    }
}