/// menu
.about-us {

    &__menu {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        @media(max-width: $mobile) {
            flex-direction: column;
            align-items: flex-start;
        }
        &--left {
            position: relative;
            img {
                max-width: 610px;
                max-height: 435px;
                height: 100%;
                @media(max-width: $mobile) {
                    width: 100%;
                }
            }
            h3 {
                font-size: 3.2em;
                font-weight: bold;
                line-height: 1;
                position: absolute;
                right: -160px;
                top: 50px;
                text-transform: uppercase;
                @media(max-width: $laptop) {
                    right: -100px;
                }
                @media(max-width: $mobile) {
                    position: relative;
                    right: auto;
                    top: auto;
                    margin-bottom: 20px;
                }
            }
        }

        &--line {
            position: absolute;
            right: -160px;
            bottom: 100px;
            width: 450px;
            height: 1px;
            background: $gold;
            @media(max-width: $laptop) {
                right: -100px;
            }
            @media(max-width: $mobile) {
                display: none;
            }
        }

        p {
            font-size: 2em;
            font-style: italic;
            margin-bottom: 30px;
            width: 610px;
            font-family: $r;
            @media(max-width: $laptop) {
                width: 100%;
            }

        }

        &--right {
            margin-left: 190px;
            @media(max-width: $laptop) {
                margin-left: 130px;
            }
            @media(max-width: $mobile) {
                margin: 30px 0 0 0;
            }
        }
    }
}