.footer-container {
    &__bottom {
        height: 40px;
        margin: 210px 0 85px 0;

        @media(max-width: $laptop){
            margin: 180px 0 65px 0;
        }
        @media(max-width: $tablet){
            margin: 140px 0 40px 0;
        }
        @media(max-width: $mobile){
            margin: 15px 0;
            height: auto;
        }
    }
}
