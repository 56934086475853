.social-media-top {
    display: flex;
    justify-content: space-between;
    margin-left: 100px;
    width: 130px;
    @media(max-width: $laptop) {
        margin: 0x 0 0 40px;
    }
    @media(max-width: $mobile) {
        margin: 5px 0 0 40px;
    }

    &__item {
        display: block;
        max-width: 23px;
        height: 14px;

        &:hover {
            .st0, .st1 {
                fill: $gold;
            }
        }
    }
}