#sectorB {
    position: relative;
    left: 260px;
    // display: none;

    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // transform: rotate(45deg);

    width: 320px;
    height: 300px;
    // border: 1px solid #000;

    // h3 {
    //     display: none;
    // }  

    .place-item {
        position: absolute;
    }

    .row1 {
        >div {
            &:nth-child(1) {
                bottom: 288px;
                left: 105px;
            } 
            &:nth-child(2) {
                bottom: 277px;
                left: 142px;
            }
            &:nth-child(3) {
                bottom: 261px;
                left: 176px;
            }
            &:nth-child(4) {
                bottom: 237px;
                left: 211px;
            }
            &:nth-child(5) {
                bottom: 200px;
                left: 245px;
            }
            &:nth-child(6) {
                bottom: 159px;
                left: 267px;
            }
            &:nth-child(7) {
                bottom: 113px;
                left: 289px;
            }
        }
    }

    .row2 {
        >div {
            &:nth-child(1) {
                bottom: 261px;
                left: 82px;
            } 
            &:nth-child(2) {
                bottom: 257px;
                left: 117px;
            }
            &:nth-child(3) {
                bottom: 242px;
                left: 149px;
            }
            &:nth-child(4) {
                bottom: 221px;
                left: 183px;
            }
            &:nth-child(5) {
                bottom: 186px;
                left: 215px;
            }
            &:nth-child(6) {
                bottom: 144px;
                left: 237px;
            }
            &:nth-child(7) {
                bottom: 103px;
                left: 258px;
            }
        }
    }

    .row3 {
        >div {
            &:nth-child(1) {
                bottom: 236px;
                left: 56px;
            } 
            &:nth-child(2) {
                bottom: 229px;
                left: 90px;
            }
            &:nth-child(3) {
                bottom: 217px;
                left: 124px;
            }
            &:nth-child(4) {
                bottom: 198px;
                left: 157px;
            }
            &:nth-child(5) {
                bottom: 167px;
                left: 183px;
            }
            &:nth-child(6) {
                bottom: 129px;
                left: 207px;
            }
            &:nth-child(7) {
                bottom: 93px;
                left: 227px;
            }
            &:nth-child(8) {
                bottom: 58px;
                left: 241px;
            }
        }
    }

    .row4 {
        >div {
            &:nth-child(1) {
                bottom: 205px;
                left: 36px;
            } 
            &:nth-child(2) {
                bottom: 203px;
                left: 66px;
            }
            &:nth-child(3) {
                bottom: 192px;
                left: 98px;
            }
            &:nth-child(4) {
                bottom: 174px;
                left: 129px;
            }
            &:nth-child(5) {
                bottom: 147px;
                left: 153px;
            }
            &:nth-child(6) {
                bottom: 114px;
                left: 174px;
            }
            &:nth-child(7) {
                bottom: 83px;
                left: 193px;
            }
            &:nth-child(8) {
                bottom: 45px;
                left: 207px;
            }
            &:nth-child(9) {
                bottom: 8px;
                left: 213px;
            }
        }
    }

    .row5 {
        >div {
            &:nth-child(1) {
                bottom: 173px;
                left: 16px;
            } 
            &:nth-child(2) {
                bottom: 169px;
                left: 46px;
            }
            &:nth-child(3) {
                bottom: 159px;
                left: 76px;
            }
            &:nth-child(4) {
                bottom: 144px;
                left: 104px;
            }
            &:nth-child(5) {
                bottom: 123px;
                left: 126px;
            }
            &:nth-child(6) {
                bottom: 96px;
                left: 144px;
            }
            &:nth-child(7) {
                bottom: 68px;
                left: 162px;
            }
            &:nth-child(8) {
                bottom: 37px;
                left: 173px;
            }
            &:nth-child(9) {
                bottom: 3px;
                left: 179px;
            }
        }
    }

    .row6 {
        >div {
            &:nth-child(1) {
                bottom: 143px;
                left: 0;
            } 
            &:nth-child(2) {
                bottom: 140px;
                left: 28px;
            }
            &:nth-child(3) {
                bottom: 129px;
                left: 53px;
            }
            &:nth-child(4) {
                bottom: 118px;
                left: 78px;
            }
            &:nth-child(5) {
                bottom: 98px;
                left: 98px;
            }
            &:nth-child(6) {
                bottom: 77px;
                left: 117px;
            }
            &:nth-child(7) {
                bottom: 54px;
                left: 131px;
            }
            &:nth-child(8) {
                bottom: 27px;
                left: 140px;
            }
            &:nth-child(9) {
                bottom: 0;
                left: 146px;
            }
        }
    }

    .row7 {
        >div {
            &:nth-child(1) {
                bottom: 110px;
                left: 0;
            }
            &:nth-child(2) {
                bottom: 106px;
                left: 27px;
            }
            &:nth-child(3) {
                bottom: 93px;
                left: 51px;
            }
            &:nth-child(4) {
                bottom: 74px;
                left: 71px;
            }
            &:nth-child(5) {
                bottom: 52px;
                left: 90px;
            }
            &:nth-child(6) {
                bottom: 27px;
                left: 105px;
            }
            &:nth-child(7) {
                bottom: 0px;
                left: 114px;
            }
        }
    }

    .row8 {
        >div {
            &:nth-child(1) {
                bottom: 75px;
                left: 0;
            }
            &:nth-child(2) {
                bottom: 68px;
                left: 27px;
            }
            &:nth-child(3) {
                bottom: 49px;
                left: 50px;
            }
            &:nth-child(4) {
                bottom: 28px;
                left: 69px;
            }
            &:nth-child(5) {
                bottom: 0px;
                left: 80px;
            }
        }
    }

    .row9 {
        >div {
            &:nth-child(1) {
                bottom: 40px;
                left: 0;
            }
            &:nth-child(2) {
                bottom: 25px;
                left: 28px;
            }
            &:nth-child(3) {
                bottom: 0px;
                left: 47px;
            }
        }
    }
}