.social-media-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 25px 0 0 -3px;
    @media(max-width: $mobile){
        width: 160px;
    }

    &__item {
        display: block;
        max-width: 23px;
        height: 21px;

        &:hover {
            .st0, .st1 {
                fill: $black;
            }
        }
    }
}