.faq-container__content--item {
    h3 {
        font-size: 1.2em;
        color: $gold;
        margin-bottom: 35px;
        @media(max-width: $mobile){
            margin-bottom: 15px;
        }
        position: relative;
        &.is-active {
            // border-bottom: 1px solid $gold;
            &:after {
                width: calc(100% + 475px);
                @media(max-width: $laptop){
                    width: calc(100%  + 395px);
                }
            }
        }


        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            height: 1px;
            width: calc(100% + 225px);
            background: $gold;
        }
    }

    li {
        font-size: 1.6em;
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 65px;
            @media(max-width: $laptop){
                margin-bottom: 45px;
            }
            @media(max-width: $tablet){
                margin-bottom: 35px;
            }
            @media(max-width: $mobile){
                margin-bottom: 25px;
            }
        }
    }

    .ask {
        font-weight: bold;
    }
}