#home-slider-events-container {
    .swiper-button-next, .swiper-button-prev {
        width: auto;
        height: auto;
        position: relative;
        color: $black;
        margin: 0;
        left: 0;
        right: 0;
        font-size: 1.4em;
        transition: .3 ease-in-out;

        &:hover {
            color: $gold;
        }

        &:after {
            content: none;
        }
    }

    .home-slider-events-arrows-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 100px auto 20px auto;
        @media(max-width: $laptop) {
            margin-top: 80px;
        }
        @media(max-width: $tablet) {
            margin-top: 60px;
        }
        @media(max-width: $mobile) {
            margin-top: 40px;
        }
        &__line {
            width: 30px;
            height: 1px;
            background-color: $black;
            margin: 0 15px;
        }
    }
}

.swiper-events-item {
    display: flex;
    @media(max-width: $tablet) {
        flex-direction: column-reverse;
    }
    &__photo {
        position: relative;
        img {
            width: 770px;
            height: 450px;
            object-fit: cover;
            object-position: center;
            @media(max-width: $laptop) {
                width: 570px;
                height: 400px;
            }
            @media(max-width: $tablet) {
                width: 100%;
                height: 400px;
            }
        }
        &--date {
            position: absolute;
            top: 25px;
            right: -65px;

            @media(max-width: $tablet) {
                right: 30px;
                top: -25px;
            }

            >div {
                background-color: $gold;
                color: #fff;
                text-align: center;
                width: 130px;
            }

            .date-top {
                font-family: $r;
                font-size: 1.6em;
                padding: 35px 0;
                @media(max-width: $tablet) {
                    padding: 25px 0;
                }
                span {
                    font-size: 60px;
                }
            }

            .date-bottom {
                margin-top: 1px;
                font-size: 1.4em;
                padding: 8px 0;
            }
        }
    }

    &__date {
        margin: auto 30px 10px 30px;
        height: 100%;
        width: 130px;
        font-size: 1.8em;

        @media(max-width: $tablet) {
            margin: 15px 0;
        }

        b {
            font-size: 12px;
            margin-right: 10px;
        }
    }

    &__content {
        padding-top: 100px;
        @media(max-width: $laptop) {
            padding-top: 70px;
        }
        @media(max-width: $tablet) {
            padding-top: 30px;
        }
        @media(max-width: $mobile) {
            padding-top: 10px;
        }
        h2 {
            font-size: 4em;
            font-weight: bold;
            margin-bottom: 20px;
        }
        p {
            font-size: 1.6em;
        }
        .button {
            margin-top: 35px;
            @media(max-width: $tablet) {
                margin-top: 15px;
            }
        }
    }
}
