.rewards-container {
    &__header {
        display: flex;
        justify-content: space-between;
        padding: 0 160px;
        margin-bottom: 55px;
        @media(max-width: $laptop) {
            padding: 80px;
        }
        @media(max-width: $tablet) {
            padding: 40px;
        }
        @media(max-width: $mobile) {
            padding: 0;
            flex-direction: column;
        }

        img {
            max-width: 610px;
            max-height: 435px;
            height: 100%;
            @media(max-width: $mobile) {
                width: 100%;
            }
        }
        >div {
            padding-top: 90px;
            width: 550px;
            margin-right: 50px;
            @media(max-width: $tablet) {
                padding-top: 40px;
            }
            @media(max-width: $mobile) {
               width: 100%;
               margin: 0;
               padding-top: 10px;
            }
            h2 {
                font-size: 4em;
                font-weight: bold;
                margin-bottom: 20px;
            }

            p {
                font-size: 2em;
                font-family: $r;
                font-style: italic;
                @media(max-width: $mobile) {
                    margin-bottom: 30px;
                 }
            }
        }
    }   

    &__list {
        margin-bottom: 80px;
        @media(max-width: $laptop) {
            margin-bottom: 60px;
        }
        @media(max-width: $tablet) {
            margin-bottom: 40px;
        }
        @media(max-width: $mobile) {
            margin-bottom: 20px;
        }
        &--header {
            display: flex;
            align-items: flex-end;
            h3 {
                margin-left: auto;
                font-size: 1.2em;
                font-weight: bold;
                margin-right: 30px;
                @media(max-width: $mobile) {
                    margin-bottom: 20px;
                }
            }
        }

        &--line {
            width: 130px;
            height: 1px;
            background: #000;
            margin-bottom: 4px;
            @media(max-width: $mobile) {
                margin-bottom: 24px;
            }
        }

        &--items {
            // margin-top: 40px;
            display: flex;
            flex-wrap: wrap;
        }
    }
}

.rewards-item {
    width: 380px;
    margin: 0 100px 50px 0;
    @media(max-width: $mobile) {
        width: 300px;
        margin: 0 15px 30px 0;
    }
    &__line {
        margin: 25px 0;
        width: 130px;
        height: 1px;
        background: $gold;
        @media(max-width: $mobile) {
            margin: 15px 0;
        }
    }

    h3 {
        font-size: 2.4em;
        font-weight: bold;
        margin-bottom: 15px;
    }

    p {
        font-size: 1.6em;
    }
}