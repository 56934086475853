footer {
    // max-height: 635px;
    background-color: $gold;
    color: #fff;
    font-size: 1.4em;
}

.footer-container {
    display: flex;
    @media(max-width: $mobile){
        flex-direction: column;
    }
    >div {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @media(max-width: $mobile){
            margin-right: 0;
            width: 100%;
        }
    }

    a {
        transition: .3s ease-in-out;
        &:hover {
            color: $black;
        }
    }

    &__column-1, &__column-2 {
        width: 290px;
        margin-right: 30px;
    }

    &__column-3 {
        width: 450px;
        margin-right: 190px;
        @media(max-width: $tablet){
            margin-right: 45px;
        }
    }

    &__column-4 {
        width: 160px;
    }

    &__column-5 {
        flex: 1;
    }
}