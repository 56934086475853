#footer-event {
    img {
        margin-right: 60px;
        max-width: 385px;
        max-height: 190px;
        width: 100%;
        height: 100%;
        @media(max-width: $mobile){
           margin: 0 0 30px 0;
         }
    }

    h3 {
        font-size: 3em;
        font-weight: bold;
    }

    p {
        font-size: 1.6em;
        font-style: italic;
        font-family: $r;
        margin: 20px 0 35px 0;
    }
}