#slider-cards {
    width: 100%;
    height: 515px;
    @media(max-width: $laptop) {
        height: 455px;
    }
    @media(max-width: $tablet) {
        height: 415px;
    }
    @media(max-width: $mobile) {
        height: 350px;
    }
    .swiper-slide {
        padding: 0 160px 125px 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        background-size: cover;
        background-repeat: no-repeat;
        @media(max-width: $laptop) {
            padding-right: 120px;
        }
        @media(max-width: $tablet) {
            padding-right: 80px;
        }
        @media(max-width: $mobile) {
            padding: 15px;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}

.slider-menu-container {
    .line {
        bottom: 90px;
        width: 770px;
        transform: translate(160px, -50%);
    }
}