.menu-container {
    margin-bottom: 290px;
    @media(max-width: $laptop) {
        margin-bottom: 200px;
    }

    @media(max-width: $tablet) {
        margin-bottom: 100px;
    }

    @media(max-width: $mobile) {
        margin-bottom: 50px;
    }

}