.ticket-container-thanks {
    padding: 25px 0 30px 0;
    width: 610px;
    margin: 0 auto;

    @media(max-width: $mobile) {
        width: 100%;
    }

    h3 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 20px;
        line-height: 1;
        text-transform: uppercase;
    }

    span {
        display: block;
        margin-top: 25px;
        font-size: 2em;
        font-family: $r;
        font-style: italic;
    }
}