.offer-photos {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    padding: 120px 140px 0 160px;
    @media(max-width: $laptop) {
        padding: 80px 0 0 80px;
    }
    @media(max-width: $tablet) {
        padding: 50px 0 0 50px;
    }
    @media(max-width: $mobile) {
        padding: 30px 0 0 0;
    }
    &.catering {
        margin: 30px 0 85px 0;
        @media(max-width: $laptop) {
            margin: 20px 0 65px 0;
        }
        @media(max-width: $tablet) {
            margin: 10px 0 45px 0;
        }
        @media(max-width: $mobile) {
            margin-bottom: 25px;
        }
    }
    >div {
        width: 100%;
    }
    img {
        max-width: 623px;
        width: 100%;
        height: 405px;
        margin-top: 30px;
        &:first-child {
            margin-right: 30px;
            @media(max-width: $mobile) {
                margin-right: 0;
            }
        }
        @media(max-width: 1900px) {
            max-width: 523px;
        }
        @media(max-width: $mobile) {
            max-width: auto;
        }
    }

    &__middle {
        display: flex;
        @media(max-width: 1900px) {
            flex-wrap: wrap;
        }
        // justify-content: space-between;
        img {
            max-width: 450px;
            height: 595px;
        }

        &--top {
            margin-left: 30px;
            @media(max-width:1900px) {
                margin-left: 0;
            }
            img {
                max-width: 320px;
                height: 205px;
                margin-right: 0 !important;
                @media(max-width: 1900px) {
                    margin-right: 30px !important;
                    height: 354px;
                }
                &:last-child {
                    margin-top: 30px;
                    height: 354px;
                }
            }
        }
    }

    &__bottom {
        width: 100%;
        img {
            height: 670px;
            max-width: 100%;
        }
    }
}