.logo {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    font-size: 1.4em;
    color: $gold;
    font-weight: 600;

    @media(max-width: $mobile){
        align-items: center;
        margin-top: 15px;
    }

    img {
        width: 200px;
        height: 45px;
    }

    span {
        display: block;
        margin-top: 5px;
    }
}