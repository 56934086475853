.faq-container {
    &__wrapper{
        display: flex;
        @media(max-width: $mobile){
            flex-direction: column;
        }
        >div {
            &:first-child {
                z-index: 9;
            }
        }
    }
}