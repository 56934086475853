.contact-container__content{
    h2 {
        font-size: 4em;
        margin-bottom: 35px;
        font-weight: bold;
        line-height: 1em;
        @media(max-width: $tablet){
           margin-bottom: 25px;
        }
        @media(max-width: $mobile){
           margin-bottom: 15px;
        }
    }

    p {
        font-family: $r;
        font-style: italic;
        font-size: 1.6em;
    }
}