.single-event-container {
    &__info {
        padding: 0 95px 125px 160px;
        position: relative;
        @media(max-width: $laptop) {
            padding-left: 80px;
        }
        @media(max-width: $tablet) {
            padding-left: 40px;
        }
        @media(max-width: $mobile) {
            padding: 0 0 15px 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            border-top: 1px solid $gold;
            border-bottom: 1px solid $gold;
            margin-top: 30px;
        }
        &:after {
            content: '';
            position: absolute;
            top: -200px;
            right: 0;
            width: 1px;
            height: calc(100% + 200px);
            background: $gold;
            z-index: 99;
            @media(max-width: $mobile) {
               display: none;
            }
        }

        >div {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            // margin-right: 40px;
        }
        .date {
            align-items: center;
            height: 155px;
            border-bottom: 1px solid $gold;
            font-family: $r;
            font-size: 16px;
            color: $gold;
            @media(max-width: $mobile) {
                border: unset;
             }
            &__top {
                font-size: 60px;
            }
        }
    
        .hour, .ticket {
            margin-top: 65px;
            @media(max-width: $mobile) {
                margin-top: 0;
             }
            span {
                font-size: 1.8em;
            }
            b {
                font-size: 12px;
                font-weight: bold;
            }
        }
        .ticket {
            margin: 25px 0 60px 0;
        }
    }
}