.about-us-slider  {
    margin-bottom: 0 !important;

    .swiper-slide {
        background-repeat: no-repeat;
        background-position: left !important;

        &__content {

            p {
                margin-bottom: -20px !important;
            }

            span {
                display: block;
                margin-top: 20px;
                position: relative;
                top: 60px;

                b {
                    font-size: 20px;
                }
                font-size: 16px;
            }
        }
    }
}

.about-us {
    &__content {
        margin: 120px 0 165px 160px;
        width: 930px;
        @media(max-width: $laptop) {
            margin: 90px 0 125px 80px;
        }
        @media(max-width: $tablet) {
            width: 100%;
            margin: 60px 0 80px 40px;
        }
        @media(max-width: $mobile) {
            margin: 30px 0 40px 0;
        }

        h2 {
            font-size: 4em;
            font-weight: bold;
            margin-bottom: 20px;
            @media(max-width: $tablet) {
                margin-bottom: 10px;
            }
        }

        span {
            font-family: $r;
            font-style: italic;
            font-size: 2em;
            display: block;
            margin-bottom: 95px;
            @media(max-width: $tablet) {
                margin-bottom: 65px;
            }
            @media(max-width: $mobile) {
                margin-bottom: 35px;
            }
        }

        p {
            font-size: 1.6em;
            margin-bottom: 45px;
            @media(max-width: $tablet) {
                margin-bottom: 25px;
            }
        }

        >div {
            display: flex;
            justify-content: flex-end;
            @media(max-width: $mobile) {
                flex-direction: column;
                justify-content: flex-start;
            }
            a {
                margin-left: 30px;
                @media(max-width: $mobile) {
                    margin-left: 0;
                    &:last-child {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}
