.sezon-menu {
    margin-bottom: 150px;
    padding-bottom: 100px;
    background-color: #f3f3f3;
    position: relative;

    @media(max-width: $laptop) {
        margin-bottom: 120px;
        padding-bottom: 80px;
    }

    @media(max-width: $tablet) {
        margin-bottom: 80px;
        padding-bottom: 60px;
    }

    @media(max-width: $mobile) {
        margin-bottom: 30px;
        padding-bottom: 10px;
    }
    &__text {
        padding: 55px 0 15px 0;
        text-align: right;
        font-size: 1.6em;
        font-weight: 600;
        margin-right: 160px;
        position: relative;

        @media(max-width: $laptop) {
            margin-right: 80px;
        }
    
        @media(max-width: $tablet) {
            margin-right: 40px;
        }
    
        @media(max-width: $mobile) {
            margin-right: 15px;
            text-align: left;
            padding-top: 25px;
        }

        &:after {
            content: '';
            width: 1px;
            height: 180px;
            background: $gold;
            position: absolute;
            right: -30px;
            bottom: 0;
            @media(max-width: $laptop) {

            }
        
            @media(max-width: $tablet) {
        
            }
        
            @media(max-width: $mobile) {
        
            }
        }
    }

    .menu-container__row > div {
        margin-bottom: 0;
    }

    &:after {
        content: '';
        width: 160px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -160px;
        background-color: #f3f3f3;
        @media(max-width: $laptop) {

        }
    
        @media(max-width: $tablet) {
    
        }
    
        @media(max-width: $mobile) {
    
        }
    }
    &:before {
        content: '';
        width: 160px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -160px;
        background-color: #f3f3f3;
        @media(max-width: $laptop) {

        }
    
        @media(max-width: $tablet) {
    
        }
    
        @media(max-width: $mobile) {
    
        }
    }
    
}